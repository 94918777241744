import { useCallback, useEffect, useRef } from "react";
import { SingleValue } from "react-select";
import { useIsPresent } from "framer-motion";

import { QuestionComponentProps } from "..";

import { useQuery } from "@otta/search/apollo";
import { AsyncSelectField } from "@otta/design";
import { CurrentUserAddressDocument } from "@otta/search/schema";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";
import { IOption } from "@otta/search/utils/select";

export function CurrentAddress({
  setNextEnabled,
}: QuestionComponentProps): React.ReactElement {
  const { preferencesData, updatePreferences } = useUserPreferences();
  const isPresent = useIsPresent();

  useEffect(() => {
    if (isPresent) {
      setNextEnabled(true);
    }
  }, [setNextEnabled, isPresent]);

  const { refetch } = useQuery(CurrentUserAddressDocument, {
    skip: true,
  });

  const timer = useRef<number>();

  const handleInputChange = useCallback(
    (
      inputValue: string,
      callback: (options: { label: string; value: string }[]) => void
    ) => {
      window.clearTimeout(timer.current);
      timer.current = window.setTimeout(async () => {
        const { data } = await refetch({ query: inputValue });

        callback(
          data.geocodingSearch.map(option => ({
            label: option.address,
            value: option.id,
          }))
        );
      }, 300);
    },
    [refetch]
  );

  const handleChange = (newValue: SingleValue<IOption>) => {
    if (newValue) {
      updatePreferences({
        currentAddress: { id: newValue.value, address: newValue.label },
      });
    } else {
      updatePreferences({ currentAddress: null });
    }
  };

  const currentAddress = preferencesData?.currentAddress;

  return (
    <AsyncSelectField
      value={
        currentAddress
          ? { label: currentAddress?.address, value: currentAddress?.id }
          : undefined
      }
      cacheOptions
      loadOptions={handleInputChange}
      onChange={handleChange}
      noOptionsMessage={() => "No results found"}
      placeholder="Start typing your address"
      isClearable
      styles={{
        noOptionsMessage: provided => ({
          ...provided,
          textAlign: "left",
        }),
        option: provided => ({
          ...provided,
          textAlign: "left",
        }),
        loadingMessage: provided => ({
          ...provided,
          textAlign: "left",
        }),
        singleValue: provided => ({
          ...provided,
          textAlign: "left",
        }),
        placeholder: provided => ({
          ...provided,
          textAlign: "left",
        }),
      }}
    />
  );
}
