import styled, { x } from "@xstyled/styled-components";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { Fragment } from "react";

import { Card as StyledCard, Text, responsive, theme } from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import { fadeIn } from "@otta/search/components/Animations";
import { PersonalisedCompanies } from "@otta/search/schema";
import { currencyPrefix } from "@otta/search/utils/currencies";
import { Icon } from "@otta/icons";
import { Asset } from "@otta/search/components/AssetSprinkler/Asset";

const Card = styled(StyledCard)`
  animation: ${fadeIn} 0.4s;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  overflow: hidden;
  color: black;
`;

const CoverImageWrapper = styled.div`
  position: relative;
  top: -xl;
  left: -xl;
  width: calc(100% + 2 * ${theme.space.xl}px);
`;

const CompanyLogo = styled.img`
  position: relative;
  width: auto;
  height: auto;
  max-height: 32px;
  max-width: 96px;
  object-fit: contain;
`;

const Factoid = styled.div`
  display: flex;
  align-items: center;
  gap: xs;
  color: black;

  svg {
    fill: currentColor;
    height: 24;
    margin-right: xs;
  }
`;

const Middle = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ResponsiveText = styled(Text)`
  ${responsive`
    font-size: ${({ size = 0 }) =>
      responsive.modularScale({ mobile: size - 1, desktop: size })}
  `}
`;

export function MiniCompanyCard({
  name,
  logoPath,
  shortDescription,
  size,
  urlSafeName,
  sectorTags,
  totalFunding,
  brandAssetGroups,
  onClick,
}: PersonalisedCompanies.PersonalisedCompanies & {
  onClick: () => void;
}): React.ReactElement {
  const coverImage = brandAssetGroups?.[0]?.links?.[0];

  return (
    <Link
      to={encodeURIComponent(urlSafeName)}
      target="_blank"
      style={{ textDecoration: "none" }}
      onClick={onClick}
    >
      <Card data-testid="mini-company-card">
        {coverImage && (
          <CoverImageWrapper>
            <Asset asset={coverImage} />
          </CoverImageWrapper>
        )}
        <x.div display="grid" gap="lg">
          {logoPath && (
            <Middle>
              <CompanyLogo src={logoPath} alt={`${name} logo`} />
            </Middle>
          )}
          <ResponsiveText align="center">{shortDescription}</ResponsiveText>
          <x.div display="flex" justifyContent="center" gap="xl">
            {size && (
              <Factoid>
                <Icon icon="users" />
                <ResponsiveText as="span" size={-1}>
                  {size.value}
                </ResponsiveText>
              </Factoid>
            )}
            {totalFunding && Number(totalFunding.amount) > 0 && (
              <Factoid>
                <Icon icon="money-bill" />

                <ResponsiveText as="span" size={-1}>
                  <NumericFormat
                    value={Number(totalFunding.amount) / 1000000}
                    prefix={
                      currencyPrefix(totalFunding.currency) ??
                      totalFunding.currency
                    }
                    decimalScale={1}
                    displayType="text"
                  />
                  m
                </ResponsiveText>
              </Factoid>
            )}
          </x.div>

          <Middle>
            {sectorTags.map((tag, index) => (
              <Fragment key={tag.id}>
                <ResponsiveText as="span" size={-1}>
                  {tag.value}
                </ResponsiveText>
                {index < sectorTags.length - 1 && (
                  <ResponsiveText
                    as="span"
                    style={{ margin: `0 ${modularScale(-8)}` }}
                  >
                    •
                  </ResponsiveText>
                )}
              </Fragment>
            ))}
          </Middle>
        </x.div>
      </Card>
    </Link>
  );
}
