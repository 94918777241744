import { createGlobalStyle } from "@xstyled/styled-components";

import { palette, pxToEm, typography } from "@otta/design-tokens";

const familyDefinitions = Object.values(typography.family);

export const styles = `${familyDefinitions
  .flatMap(({ name, src }) =>
    src.map(
      ({ weight, sources }) => `@font-face {
font-family: ${name};
font-style: normal;
font-weight: ${weight};
font-display: swap;
src: ${sources
        .map(({ format, url }) => `url('${url}') format('${format}')`)
        .join(", ")};
}`
    )
  )
  .join("\n\n")}

  @font-face {
    font-family: 'otta-icons';
    src: url('https://static.otta.com/fonts/icons/otta-icons.317fbcbcb3b56216181bde2d2f6a178592754715.ttf')
        format('embedded-opentype'),
      url('https://static.otta.com/fonts/icons/otta-icons.317fbcbcb3b56216181bde2d2f6a178592754715.woff2') format('woff2'),
      url('https://static.otta.com/fonts/icons/otta-icons.317fbcbcb3b56216181bde2d2f6a178592754715.woff') format('woff');
  }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: ${pxToEm(typography.baseFontSize)};
  font-family: var(--body-font, ${
    typography.family.body.name
  }), -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  line-height: 1.3;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  background: ${palette.beige.shade200};
}
[data-sensitive-field='true'] {
  filter: blur(3px);
}
`;

export const GlobalStyles = createGlobalStyle`
  ${styles}

  strong, b {
    font-weight: 600;
  }
`;

const fontsToPreload = familyDefinitions
  .flatMap(def => def.src.flatMap(src => src.sources || []))
  .filter(({ format }) => format === "woff2");

export function Preload() {
  return (
    <>
      {fontsToPreload.map(({ format, url }) => (
        <link
          key={url}
          rel="preload"
          href={url}
          as="font"
          type={`font/${format}`}
          crossOrigin="anonymous"
        />
      ))}
    </>
  );
}
