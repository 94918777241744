import styled, { css, up } from "@xstyled/styled-components";
import { useMemo } from "react";

import { GlassdoorAndTrustpilot } from "./GlassdoorAndTrustpilot";
import { Highlights } from "./Highlights";

import { Text, VerticalSpacing } from "@otta/design";
import { modularScale, palette, pxToRem } from "@otta/design-tokens";
import { Company, Job } from "@otta/search/schema";
import { Icon } from "@otta/icons";

const COLORS = {
  good: palette.extended.green.shade400,
  medium: palette.grayscale.shade400,
  bad: palette.extended.red.shade500,
} as const;

const InsightsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  // to cancel out margin from last row
  margin-bottom: -md;
`;

const InsightContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: md;
  flex-grow: 1;
  gap: sm;

  > p {
    font-size: ${pxToRem(16)};
  }
`;

const InsightRing = styled.div<{ borderColor: string }>`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: 2px solid ${({ borderColor }) => borderColor};
  font-size: ${modularScale(-1)};
  width: 32px;
  height: 32px;

  > svg {
    width: 16px;
    height: 16px;
  }

  ${up(
    "desktop",
    css`
      margin-left: 0;
      margin-right: 0;
    `
  )}
`;

type ValidColor =
  | (typeof COLORS)[keyof typeof COLORS]
  | typeof palette.brand.black;

const getColor = (
  value: number,
  minValues: {
    good: number;
    medium: number;
    bad: number;
  }
): ValidColor => {
  for (const [category, minVal] of Object.entries(minValues)) {
    if (value >= minVal) {
      return COLORS[category as keyof typeof minValues];
    }
  }
  return palette.brand.black;
};

interface IInsight {
  description: React.ReactNode;
  color: ValidColor;
  icon: React.ReactElement;
  id: string;
}

const getEmployeeGrowthInsight = (
  employeeGrowthPercentage: number | null
): IInsight[] => {
  if (employeeGrowthPercentage === null) {
    return [];
  }

  return [
    {
      description: (
        <Text size={-1}>
          <strong>{employeeGrowthPercentage}%</strong> employee growth in 12
          months
        </Text>
      ),
      color: getColor(employeeGrowthPercentage, {
        good: 5,
        medium: -5,
        bad: -100,
      }),
      icon: <Icon icon="chart-up" size={1} />,
      id: "employee-growth-insight",
    },
  ];
};

const getGenderDiversityInsight = (
  percentageFemale: number | null
): IInsight[] => {
  if (percentageFemale === null || percentageFemale < 0) {
    return [];
  }

  return [
    {
      description: (
        <Text size={-1}>
          <strong>{percentageFemale}%</strong> female employees
        </Text>
      ),
      color: getColor(percentageFemale, {
        good: 40,
        medium: 10,
        bad: 0,
      }),
      icon: <Icon icon="female" size={1} />,
      id: "gender-diversity-insight",
    },
  ];
};

const getInsights = (company: InsightsProps["company"]): IInsight[] => [
  ...getGenderDiversityInsight(company.percentageFemale),
  ...getEmployeeGrowthInsight(company.yearEmployeeGrowthPercentage),
];

export const shouldDisplayInsights = (company: InsightsProps["company"]) => {
  return !(!company.glassdoorRating && !company.trustpilotRating);
};

interface InsightsProps {
  job?: Pick<Job.Fragment, "activelyHiring">;
  company: Pick<
    Company.Fragment,
    | "percentageFemale"
    | "yearEmployeeGrowthPercentage"
    | "glassdoorRating"
    | "glassdoorUrl"
    | "trustpilotRating"
    | "trustpilotUrl"
    | "founders"
    | "bcorp"
    | "id"
    | "investors"
  >;
}

export function Insights({
  job,
  company,
}: InsightsProps): React.ReactElement | null {
  const insights = useMemo(() => getInsights(company), [company]);

  return (
    <VerticalSpacing>
      <Highlights company={company} activelyHiring={job?.activelyHiring} />
      <InsightsContainer data-testid="insights">
        {insights.map(insight => (
          <Insight key={insight.id} {...insight} />
        ))}
        <GlassdoorAndTrustpilot company={company} />
      </InsightsContainer>
    </VerticalSpacing>
  );
}

function Insight({
  description,
  color,
  icon,
  id,
}: IInsight): React.ReactElement {
  return (
    <InsightContainer data-testid={id}>
      <div>
        <InsightRing borderColor={color}>{icon}</InsightRing>
      </div>
      {description}
    </InsightContainer>
  );
}
