import { useMemo } from "react";

import { JobCompanyTeamFragment } from "@otta/search/schema";
import { Card, Spacing, Tabs } from "@otta/design";
import { TeamSize } from "@otta/search/components/MeetTheTeam/TeamSize";
import { AssetSprinkler } from "@otta/search/components/AssetSprinkler";
import { MissionCard } from "@otta/search/components/MeetTheTeam/MissionCard";

export function MeetTheTeamTab({ teams }: { teams: JobCompanyTeamFragment[] }) {
  const tabs = useMemo(
    () =>
      teams.map(team => ({
        content: <MeetOneTeam team={team} />,
        id: team.id,
        title: team.name,
      })),
    [teams]
  );

  return <Tabs tabs={tabs} />;
}

function MeetOneTeam({ team }: { team: JobCompanyTeamFragment }) {
  return (
    <>
      <Card>
        <Spacing size={-3}>
          <TeamSize size={team.size} />
          {team.mission && <MissionCard>{team.mission}</MissionCard>}
        </Spacing>
      </Card>
      <Card>
        <AssetSprinkler assets={team.brandAssetLinks} />
      </Card>
    </>
  );
}
