import { useMemo, useState } from "react";
import styled, { x, css, up } from "@xstyled/styled-components";
import {
  useLocation,
  NavLink,
  Link as RouterLink,
  matchPath,
  useMatch,
  useSearchParams,
} from "react-router-dom";

import { Home, Jobs, Mail, Account } from "./icons";
import { Companies } from "./icons/Companies";

import {
  Text,
  Dropdown,
  DropdownItem,
  WttjLogo,
  WttjSymbol,
} from "@otta/design";
import { palette } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import { NavBarStatus, NavBarStatusDocument } from "@otta/search/schema";
import { NAV_HEIGHT } from "@otta/search/globalConstants";
import { useUserLocation } from "@otta/search/hooks/useUserLocation";
import { Country } from "@otta/search/constants/locations";

const Header = styled.header`
  background: ${palette.brand.white};
  padding: 0 md;
  z-index: 10;

  ${up(
    "tablet",
    css`
      &[data-sticky="true"] {
        position: sticky;
        top: 0;
      }
    `
  )}
`;

const StyledDropdownItem = styled(DropdownItem)`
  padding: 0;
`;

const DropdownLink = styled(NavLink)`
  text-decoration: none;
  color: black;
  padding: md lg;
  display: block;
  text-align: right;
`;

const Nav = styled.nav`
  max-width: 1198;
  min-height: ${NAV_HEIGHT};
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 350px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
const LinkWrapper = styled.div`
  display: contents;

  &[hidden] {
    display: none;
  }
`;

const Links = styled.ul`
  display: flex;
  align-items: center;
  gap: sm;

  ${up(
    "tablet",
    css`
      gap: xl;
    `
  )}
`;

const Link = styled.li`
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
`;

const YouTrigger = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

const StyledLink = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: xxs;
  height: ${NAV_HEIGHT};
  color: black;
  text-decoration: none;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
  }

  &:hover::after {
    background-color: gray-100;
  }

  &[data-active]::after,
  &.active::after {
    background-color: yellow-500;
  }

  &:has(svg) {
    min-width: 44;
  }
`;

const Dot = styled.span`
  position: absolute;
  top: 12;
  left: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20;
  height: 20;
  background-color: red-600;
  color: white;
  border-radius: 50%;
`;

// https://stackoverflow.com/a/20249560 - we use an invisible bold element to pre-set the width so there's no jump when the text weight changes
const StyledText = styled(Text).attrs({ as: "span" })`
  text-align: center;
  &:after {
    display: block;
    content: attr(data-title);
    font-weight: 600;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
`;

export function NavBar(): React.ReactElement | null {
  const location = useLocation();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onboarding = matchPath(location.pathname, "/initial-preferences");

  const hideLinks = !!(location.state as { onboard?: boolean } | undefined)
    ?.onboard;

  const { data, loading } = useQuery(NavBarStatusDocument, { ssr: true });
  const [params] = useSearchParams();

  const isRecruiter = data?.me?.__typename === "CurrentCompanyRecruiter";
  const isCandidate = data?.me?.__typename === "CurrentCandidate";
  const hideNav = !isCandidate && !!params.get("if");

  const candidateData =
    data?.me?.__typename === "CurrentAdmin" ||
    data?.me?.__typename === "CurrentCandidate"
      ? data?.me
      : null;

  const { id: userId, conversations = [] } = candidateData ?? {};

  const totalUnread = useMemo(() => {
    const unreadMessage = (conversation: NavBarStatus.Conversations) =>
      conversation.latestMessage?.read !== true &&
      conversation.latestMessage?.sender.id !== userId;

    const unreadSourcingMessage = (conversation: NavBarStatus.Conversations) =>
      conversation.sourced && conversation.hasAcceptedRequest === null;

    const unreadConversations = conversations.filter(
      conversation =>
        unreadMessage(conversation) || unreadSourcingMessage(conversation)
    );

    return unreadConversations.length;
  }, [userId, conversations]);

  const highlightYou =
    location.pathname.includes("/account-settings") ||
    location.pathname.includes("/profile") ||
    location.pathname.includes("/preferences") ||
    dropdownOpen;

  const isHeaderSticky = useMatch("/jobs/:jobId/apply");

  return (
    <Header data-sticky={!!isHeaderSticky}>
      {!hideNav && (
        <Nav>
          <Logo />

          {!loading && data?.me && (
            <Links>
              {isRecruiter ? (
                <Link>
                  <StyledLink href={`https://hire.welcometothejungle.com`}>
                    Hire
                  </StyledLink>
                </Link>
              ) : (
                !onboarding && (
                  <LinkWrapper hidden={hideLinks}>
                    <LoggedInLink to="/" text="Home" icon={Home} />
                    <LoggedInLink to="/jobs" text="Jobs" icon={Jobs} />
                    <LoggedInLink
                      to="/companies"
                      text="Companies"
                      icon={Companies}
                    />
                    <Link>
                      <StyledLink as={NavLink} to="/messaging">
                        {({ isActive }: { isActive: boolean }) => (
                          <>
                            <Mail height="20" width="20" active={isActive} />
                            <StyledText
                              data-title="Inbox"
                              size={-1}
                              bold={isActive}
                            >
                              Inbox
                            </StyledText>
                            {totalUnread > 0 && (
                              <Dot data-testid="unread-message-dot">
                                <Text bold size={-1}>
                                  {totalUnread}
                                </Text>
                              </Dot>
                            )}
                          </>
                        )}
                      </StyledLink>
                    </Link>
                    <Link>
                      <Dropdown
                        open={dropdownOpen}
                        onClick={setDropdownOpen}
                        placement="bottom"
                        align="end"
                        toggler={
                          <StyledLink
                            data-active={highlightYou ? true : null}
                            as={YouTrigger}
                          >
                            <Account
                              width="20"
                              height="20"
                              active={highlightYou}
                            />
                            <StyledText
                              data-title="You"
                              size={-1}
                              bold={highlightYou}
                            >
                              You
                            </StyledText>
                          </StyledLink>
                        }
                      >
                        <StyledDropdownItem
                          onClick={() => setDropdownOpen(false)}
                          role="menuitem"
                        >
                          <DropdownLink to="/profile">Profile</DropdownLink>
                        </StyledDropdownItem>
                        <StyledDropdownItem
                          onClick={() => setDropdownOpen(false)}
                          role="menuitem"
                        >
                          <DropdownLink to="/preferences">
                            Search preferences
                          </DropdownLink>
                        </StyledDropdownItem>
                        <StyledDropdownItem
                          onClick={() => setDropdownOpen(false)}
                          role="menuitem"
                        >
                          <DropdownLink to="/account-settings">
                            Account settings
                          </DropdownLink>
                        </StyledDropdownItem>
                        <StyledDropdownItem
                          onClick={() => setDropdownOpen(false)}
                          role="menuitem"
                        >
                          <DropdownLink
                            to="/logout"
                            style={{
                              color: palette.brand.red,
                            }}
                          >
                            Log out
                          </DropdownLink>
                        </StyledDropdownItem>
                      </Dropdown>
                    </Link>
                  </LinkWrapper>
                )
              )}
            </Links>
          )}
          {!loading && !data?.me && !isRecruiter && (
            <Links>
              <LoggedOutLink
                to="/initial-preferences"
                text="Explore jobs"
                mobileText="Jobs"
              />
              <LoggedOutLink
                to="/companies"
                text="Discover companies"
                mobileText="Companies"
              />
              <LoggedOutLink to="/login" text="Sign in" />
            </Links>
          )}
        </Nav>
      )}
    </Header>
  );
}

const StyledWttjLogo = styled(WttjLogo)`
  display: block;
  height: 28;
`;

function Logo() {
  const { data } = useQuery(NavBarStatusDocument, {
    fetchPolicy: "cache-only",
  });
  const isLoggedIn = !!data?.me;

  let baseUrl = "global";
  const { country } = useUserLocation();
  if (country === Country.US) {
    baseUrl = "us";
  } else if (country === Country.UK) {
    baseUrl = "uk";
  }
  const url = `https://${baseUrl}.welcometothejungle.com`;

  return (
    <RouterLink to={url} aria-label="Logo" data-analytics-id="nav-logo">
      {!isLoggedIn && <StyledWttjLogo />}
      {isLoggedIn && (
        <>
          <x.div display={{ tablet: "none" }} w={28} h={28}>
            <WttjSymbol />
          </x.div>
          <x.div display={{ _: "none", tablet: "block" }}>
            <StyledWttjLogo />
          </x.div>
        </>
      )}
    </RouterLink>
  );
}

function LoggedInLink({
  text,
  to,
  icon: Icon,
}: {
  text: string;
  to: string;
  icon: React.FC<{ width: string; height: string; active: boolean }>;
}) {
  return (
    <Link>
      <StyledLink
        as={NavLink}
        to={to}
        aria-label={text}
        data-analytics-id={`nav-${text.toLowerCase()}`}
      >
        {({ isActive }: { isActive: boolean }) => (
          <>
            <Icon width="20" height="20" active={isActive} />
            <StyledText data-title={text} bold={isActive} size={-1}>
              {text}
            </StyledText>
          </>
        )}
      </StyledLink>
    </Link>
  );
}

function LoggedOutLink({
  to,
  text,
  mobileText = text,
}: {
  to: string;
  text: string;
  mobileText?: string;
}) {
  return (
    <Link>
      <StyledLink
        as={NavLink}
        to={to}
        data-analytics-id={`nav-${text.toLowerCase()}`}
      >
        {({ isActive }: { isActive: boolean }) => (
          <>
            <x.span display={{ tablet: "none" }}>
              <StyledText data-title={mobileText} bold={isActive}>
                {mobileText}
              </StyledText>
            </x.span>
            <x.span display={{ _: "none", tablet: "block" }}>
              <StyledText data-title={text} bold={isActive}>
                {text}
              </StyledText>
            </x.span>
          </>
        )}
      </StyledLink>
    </Link>
  );
}
