interface CompaniesProps extends React.SVGAttributes<SVGSVGElement> {
  active?: boolean;
}

export function Companies({
  active,
  ...props
}: CompaniesProps): React.ReactElement {
  return active ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.3125 1.66663C4.44987 1.66663 3.75 2.3665 3.75 3.22913V16.7708C3.75 17.6334 4.44987 18.3333 5.3125 18.3333H8.4375V15.7291C8.4375 14.8665 9.13737 14.1666 10 14.1666C10.8626 14.1666 11.5625 14.8665 11.5625 15.7291V18.3333H14.6875C15.5501 18.3333 16.25 17.6334 16.25 16.7708V3.22913C16.25 2.3665 15.5501 1.66663 14.6875 1.66663H5.3125ZM5.83333 9.47913C5.83333 9.19267 6.06771 8.95829 6.35417 8.95829H7.39583C7.68229 8.95829 7.91667 9.19267 7.91667 9.47913V10.5208C7.91667 10.8073 7.68229 11.0416 7.39583 11.0416H6.35417C6.06771 11.0416 5.83333 10.8073 5.83333 10.5208V9.47913ZM9.47917 8.95829H10.5208C10.8073 8.95829 11.0417 9.19267 11.0417 9.47913V10.5208C11.0417 10.8073 10.8073 11.0416 10.5208 11.0416H9.47917C9.19271 11.0416 8.95833 10.8073 8.95833 10.5208V9.47913C8.95833 9.19267 9.19271 8.95829 9.47917 8.95829ZM12.0833 9.47913C12.0833 9.19267 12.3177 8.95829 12.6042 8.95829H13.6458C13.9323 8.95829 14.1667 9.19267 14.1667 9.47913V10.5208C14.1667 10.8073 13.9323 11.0416 13.6458 11.0416H12.6042C12.3177 11.0416 12.0833 10.8073 12.0833 10.5208V9.47913ZM6.35417 4.79163H7.39583C7.68229 4.79163 7.91667 5.026 7.91667 5.31246V6.35413C7.91667 6.64058 7.68229 6.87496 7.39583 6.87496H6.35417C6.06771 6.87496 5.83333 6.64058 5.83333 6.35413V5.31246C5.83333 5.026 6.06771 4.79163 6.35417 4.79163ZM8.95833 5.31246C8.95833 5.026 9.19271 4.79163 9.47917 4.79163H10.5208C10.8073 4.79163 11.0417 5.026 11.0417 5.31246V6.35413C11.0417 6.64058 10.8073 6.87496 10.5208 6.87496H9.47917C9.19271 6.87496 8.95833 6.64058 8.95833 6.35413V5.31246ZM12.6042 4.79163H13.6458C13.9323 4.79163 14.1667 5.026 14.1667 5.31246V6.35413C14.1667 6.64058 13.9323 6.87496 13.6458 6.87496H12.6042C12.3177 6.87496 12.0833 6.64058 12.0833 6.35413V5.31246C12.0833 5.026 12.3177 4.79163 12.6042 4.79163Z"
        fill="#212121"
      />
    </svg>
  ) : (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.88881 5.05216C6.88881 4.7644 7.13742 4.53133 7.44436 4.53133H9.11103C9.41659 4.53133 9.66659 4.7644 9.66659 5.05216V6.61466C9.66659 6.90112 9.41659 7.1355 9.11103 7.1355H7.44436C7.13742 7.1355 6.88881 6.90112 6.88881 6.61466V5.05216ZM13.5555 4.53133C13.861 4.53133 14.111 4.7644 14.111 5.05216V6.61466C14.111 6.90112 13.861 7.1355 13.5555 7.1355H11.8888C11.5833 7.1355 11.3333 6.90112 11.3333 6.61466V5.05216C11.3333 4.7644 11.5833 4.53133 11.8888 4.53133H13.5555ZM6.88881 9.21883C6.88881 8.93237 7.13742 8.698 7.44436 8.698H9.11103C9.41659 8.698 9.66659 8.93237 9.66659 9.21883V10.7813C9.66659 11.0678 9.41659 11.3022 9.11103 11.3022H7.44436C7.13742 11.3022 6.88881 11.0678 6.88881 10.7813V9.21883ZM13.5555 8.698C13.861 8.698 14.111 8.93237 14.111 9.21883V10.7813C14.111 11.0678 13.861 11.3022 13.5555 11.3022H11.8888C11.5833 11.3022 11.3333 11.0678 11.3333 10.7813V9.21883C11.3333 8.93237 11.5833 8.698 11.8888 8.698H13.5555ZM3.83325 3.75008C3.83325 2.59937 4.82804 1.66675 6.05547 1.66675H14.9444C16.1701 1.66675 17.1666 2.59937 17.1666 3.75008V16.2501C17.1666 17.3992 16.1701 18.3334 14.9444 18.3334H6.05547C4.82804 18.3334 3.83325 17.3992 3.83325 16.2501V3.75008ZM5.49992 3.75008V16.2501C5.49992 16.5365 5.74853 16.7709 6.05547 16.7709H8.83325V14.6876C8.83325 13.825 9.57978 13.1251 10.4999 13.1251C11.4201 13.1251 12.1666 13.825 12.1666 14.6876V16.7709H14.9444C15.2499 16.7709 15.4999 16.5365 15.4999 16.2501V3.75008C15.4999 3.46232 15.2499 3.22925 14.9444 3.22925H6.05547C5.74853 3.22925 5.49992 3.46232 5.49992 3.75008Z"
        fill="black"
      />
    </svg>
  );
}
