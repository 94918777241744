import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled, { css } from "@xstyled/styled-components";

import { OAuthLogin } from "./Login";
import { Confirm } from "./Confirm";
import { MobileAppPasswordReset } from "./MobileAppResetPassword";

import { modularScale } from "@otta/design-tokens";
import { Card } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Spinner } from "@otta/search/components/Loading";
import { OAuthAuthorizeUserDocument } from "@otta/search/schema";

const Container = styled.div<{ withMargin?: boolean }>`
  ${({ withMargin }) =>
    withMargin &&
    css`
      margin: ${modularScale(5)} ${modularScale()} ${modularScale()}
        ${modularScale()};
    `}
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
`;

export function Authorize(): React.ReactElement {
  const { data, loading, refetch } = useQuery(OAuthAuthorizeUserDocument, {
    fetchPolicy: "no-cache",
  });

  const [page, setPage] = useState<"logged-in" | "login" | "forgot-password">();

  const initialPage = data?.currentUser ? "logged-in" : "login";

  const currentPage = page ?? initialPage;

  const CurrentPageComponent = (() => {
    if (currentPage === "logged-in" && data?.currentUser) {
      return (
        <Confirm user={data.currentUser} onUseOther={() => setPage("login")} />
      );
    } else if (currentPage === "login") {
      return (
        <OAuthLogin
          onLogin={refetch}
          onForgotPassword={() => setPage("forgot-password")}
        />
      );
    } else if (currentPage === "forgot-password") {
      return <MobileAppPasswordReset onPrevious={() => setPage("login")} />;
    } else {
      return null;
    }
  })();

  useEffect(() => {
    if (!loading && CurrentPageComponent === null) {
      setPage(initialPage);
    }
  }, [loading, CurrentPageComponent, initialPage]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Container style={{ background: "white" }}>
      <Card
        style={{
          paddingTop: 40,
          position: "relative",
          overflowX: "hidden",
          width: "100%",
          maxWidth: 400,
          flexGrow: 1,
          borderRadius: 0,
        }}
      >
        <AnimatePresence mode="popLayout" initial={false}>
          <motion.div
            key={currentPage}
            initial={{ x: "100%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
          >
            {CurrentPageComponent}
          </motion.div>
        </AnimatePresence>
      </Card>
    </Container>
  );
}
