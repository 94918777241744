import { x } from "@xstyled/styled-components";

import { JobCardSection } from "./JobCardSection";

import { JobCompanyTeamFragment, JobFragment } from "@otta/search/schema";
import { TeamSize } from "@otta/search/components/MeetTheTeam/TeamSize";
import { MissionCard } from "@otta/search/components/MeetTheTeam/MissionCard";
import { TeamAssets } from "@otta/search/components/MeetTheTeam/TeamAssets";
import { Tabs } from "@otta/design";
import { useEBTracking } from "@otta/search/contexts/EBTracking";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useJobTeams } from "@otta/search/utils/job";

export function MeetTheTeamSection({
  job,
}: {
  job: JobFragment;
}): React.ReactElement {
  const trackingData = useEBTracking();

  const teams = useJobTeams(job);

  return (
    <JobCardSection title="Meet the team" id="meet-the-team">
      <Tabs
        tabs={teams.map(team => ({
          id: team.id,
          title: team.name,
          content: <MeetOneTeam team={team} />,
        }))}
        onChange={(tab: string) => {
          const currentTeam = teams.find(t => t.id === tab);

          pushAnalyticsEvent({
            eventName: "Candidate Clicked Team Name",
            teamName: currentTeam?.name,
            teamId: currentTeam?.id,
            teamCount: teams.length,
            companyId: trackingData.companyId,
            loginStatus: trackingData.loginStatus ? "logged-in" : "logged-out",
          });
        }}
      />
    </JobCardSection>
  );
}

function MeetOneTeam({ team }: { team: JobCompanyTeamFragment }) {
  return (
    <x.div
      display="flex"
      flexDirection="column"
      gap="xl"
      data-testid="mtt-content"
    >
      {!!team.size && <TeamSize size={team.size} />}
      <TeamAssets assets={team.brandAssetLinks} />
      {!!team.mission && <MissionCard>{team.mission}</MissionCard>}
    </x.div>
  );
}
