import styled, { keyframes } from "@xstyled/styled-components";

import { CompanyFragment } from "@otta/search/schema";
import { borderRadius } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Tooltip } from "@otta/design";

const wave = keyframes`
  0% { transform: rotate(0deg); }
  20% { transform: rotate(16deg); }
  40% { transform: rotate(-16deg); }
  60% { transform: rotate(8deg); }
  80% { transform: rotate(-8deg); }
  100% { transform: rotate(0deg); }
`;

const HandWaveIcon = styled(Icon).attrs({
  icon: "hand-wave",
  size: 1,
})`
  transform-origin: 90% 90%;
`;

const MeetTheTeamTagWrapper = styled.a`
  display: inline-flex;
  align-items: center;
  gap: sm;
  background-color: pink-400;
  padding: xs sm;
  border-radius: ${borderRadius};
  font-weight: 600;
  color: inherit;
  text-decoration: none;
  cursor: grab;

  @media (prefers-reduced-motion: no-preference) {
    &:hover ${HandWaveIcon} {
      animation: ${wave} 1s ease-in-out;
    }
  }
`;

export const MeetTheTeamTag = ({
  company,
}: {
  company: Pick<CompanyFragment, "name" | "teams">;
}) => {
  if (company.teams.length === 0) return null;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const meetTheTeamElement = document.getElementById("meet-the-team");

    if (meetTheTeamElement) {
      meetTheTeamElement.scrollIntoView({
        behavior: "smooth",
      });
      // Put the hash back since we prevented it
      // this won't scroll directly to the element
      history.pushState(null, "", "#meet-the-team");
    }
  };

  return (
    <Tooltip
      content={`We’ve partnered with ${company.name} to give you a unique insight into the team and what it’s like to work there.`}
      placement="bottom"
    >
      <MeetTheTeamTagWrapper href="#meet-the-team" onClick={handleClick}>
        <HandWaveIcon />
        Meet the team
      </MeetTheTeamTagWrapper>
    </Tooltip>
  );
};
