import { FieldFunctionOptions, TypePolicies } from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      salaryStatistics: {
        merge: true,
      },
      userLocationPreferences: {
        merge: true,
      },
      topSectorTags: offsetLimitPagination(),
      topInvestors: offsetLimitPagination(),
      personalisedCompanies: offsetLimitPagination([
        "companyName",
        "companySizes",
        "sectorTagPreferences",
        "investorPreferences",
        "locationPreferences",
      ]),
    },
  },
  User: {
    fields: {
      personalisedJobs: {
        merge: false,
      },
      ethnicities: {
        merge: false,
      },
      shortlistedJobs: {
        merge: false,
      },
      projects: {
        merge: false,
      },
      contextQuestionResponses: {
        merge: false,
      },
      discardedJobs: {
        keyArgs: false,
        merge(existing = [], incoming = [], { args }: FieldFunctionOptions) {
          return args?.offset > 0 ? [...existing, ...incoming] : incoming;
        },
      },
      jobRecommendations: {
        keyArgs(args) {
          return [
            `${args?.themeId ?? "nil"}`,
            `${args?.preferredSectorId ?? "nil"}`,
          ].join("_");
        },
      },
      hiddenCompanies: {
        merge: false,
      },
    },
  },
  UserWorkExperience: {
    fields: {
      technologiesUsed: {
        merge: false,
      },
    },
  },
  JobApplication: {
    fields: {
      questionResponses: {
        merge: false,
      },
    },
  },
  CompanySizePreference: {
    keyFields: ["companySizeId"],
  },
  CompanyQualityPreference: {
    keyFields: ["companyQualityId"],
  },
  JobFunctionPreference: {
    keyFields: ["functionId"],
  },
  JobSubFunctionPreference: {
    keyFields: ["subFunctionId"],
  },
  SectorTagPreference: {
    keyFields: ["sectorTagId"],
  },
  TechnologyUsedPreference: {
    keyFields: ["technologyUsedId"],
  },
  LanguageRequirementPreference: {
    keyFields: ["languageRequirementId"],
  },
  LocationPreference: {
    keyFields: ["location"],
  },
  Ethnicity: {
    keyFields: ["ethnicity"],
  },
  VisaRequirementCountry: {
    keyFields: ["location"],
  },
  SubscriptionChannel: {
    keyFields: false,
  },
};
