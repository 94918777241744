import styled, { css, down, up } from "@xstyled/styled-components";
import { useMemo } from "react";

import { Carousel } from "../Carousel";

import { Asset } from "./Asset";

import {
  BrandAssetGroup,
  BrandAssetType,
  BrandAssetWidth,
  CompanyFragment,
  CompanyProfileLinkFragment,
} from "@otta/search/schema";
import { Text } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";

const FlexDiv = styled.div`
  flex-wrap: wrap;
  display: flex;
  gap: 1rem;

  &:empty {
    display: none;
  }
`;

const MobileCarousel = styled(Carousel)`
  display: none;
  ${down(
    "tablet",
    css`
      display: block;
    `
  )}
`;

const Assets = styled.div<{ fullWidth?: boolean }>`
  ${p => (p.fullWidth ? "width: 100%;" : "")}
  grid-template-columns: repeat(6, 1fr);
  display: grid;
  margin: auto;
  gap: 1rem;
`;

const NonMobileAsset = styled(Asset)`
  ${down(
    "tablet",
    css`
      display: none;
    `
  )}
`;

// crimes ahoy 👀
const CarouselAsset = styled(Asset)`
  height: ${pxToRem(350)};
  & img {
    flex: 0 0 auto;
    margin: 0 auto;
    max-height: 100%;
  }
`;

function SprinkledAsset({
  carouselMode,
  brandAsset,
  "data-testid": testId,
}: {
  carouselMode: boolean;
  brandAsset: CompanyProfileLinkFragment;
  "data-testid"?: string;
}) {
  return brandAsset.companyBrandAsset.type === BrandAssetType.Image &&
    carouselMode ? (
    <NonMobileAsset asset={brandAsset} data-testid={testId} />
  ) : (
    <Asset asset={brandAsset} data-testid={testId} />
  );
}

/**
 * Some hacky shennanigans to make one-third images 6 grid cells
 * if they're the only asset on desktop, so they fit to the side of the content.
 */
function desktopWidth({ width }: { width: BrandAssetWidth }): string {
  return (
    `grid-column-end: span ${gridWidth(width)};` +
    (width === BrandAssetWidth.OneThird
      ? "&:only-child { grid-column-end: span 6; }"
      : "")
  );
}

function AssetTitle({
  asset,
}: {
  asset: CompanyProfileLinkFragment["companyBrandAsset"];
}): React.ReactElement | null {
  return asset.type === BrandAssetType.Vimeo && asset.longTitle ? (
    <Text size={0} bold>
      {asset.longTitle}
    </Text>
  ) : null;
}

const AssetContainer = styled.div<{ asset: CompanyProfileLinkFragment }>`
  ${({ asset: { mobilePlacement: mobile } }) =>
    mobile && `grid-column-end: span ${gridWidth(mobile.width)};`}
  ${({ asset: { desktopPlacement: desktop } }) =>
    desktop &&
    up(
      "tablet",
      css`
        ${desktopWidth(desktop)}
      `
    )}
  flex-direction: column;
  display: flex;
  gap: 0.5rem;
`;

const Content = styled.div`
  margin-bottom: 1rem;
  flex-basis: 60%;
  flex-grow: 1;
`;

function gridWidth(width: BrandAssetWidth): number {
  switch (width) {
    case BrandAssetWidth.OneThird:
      return 2;
    case BrandAssetWidth.OneHalf:
      return 3;
    case BrandAssetWidth.TwoThirds:
      return 4;
    case BrandAssetWidth.Full:
      return 6;
  }
}

/**
 * Wraps some content and adds assets around it such that
 * a portrait picture will usually go beside the content
 * but landscape pictures / multiple pictures etc go after
 */
export function AssetGroupSprinkler({
  "data-testid": testId,
  mobileCarousel,
  noTitles,
  children,
  assets,
  group,
}: React.PropsWithChildren<{
  "data-testid"?: string;
  group?: BrandAssetGroup;
  mobileCarousel?: boolean;
  noTitles?: boolean;
  assets?: CompanyFragment["brandAssetGroups"];
}>): React.ReactElement {
  const found: CompanyProfileLinkFragment[] = useMemo(
    () => assets?.find(a => a.group.name === group)?.links ?? [],
    [assets, group]
  );
  return (
    <AssetSprinkler
      assets={found}
      data-testid={testId}
      mobileCarousel={mobileCarousel}
      noTitles={noTitles}
    >
      {children}
    </AssetSprinkler>
  );
}

export function AssetSprinkler({
  "data-testid": testId,
  mobileCarousel,
  noTitles,
  children,
  assets,
  className,
}: React.PropsWithChildren<{
  "data-testid"?: string;
  mobileCarousel?: boolean;
  noTitles?: boolean;
  assets?: CompanyProfileLinkFragment[];
  className?: string;
}>): React.ReactElement {
  // don't worry I hate this too
  const hasVideo: boolean = useMemo(
    () =>
      assets?.some(a => a.companyBrandAsset.type === BrandAssetType.Vimeo) ??
      false,
    [assets]
  );

  const sprinkledAssets = useMemo(() => {
    return assets?.filter(a => !!a.mobilePlacement) ?? [];
  }, [assets]);

  const carouselImages = useMemo(
    () =>
      mobileCarousel
        ? assets?.flatMap(asset =>
            asset.companyBrandAsset.type === BrandAssetType.Image
              ? [<CarouselAsset asset={asset} key={asset.id} />]
              : []
          )
        : [],
    [assets, mobileCarousel]
  );

  const carouselMode: boolean =
    (carouselImages && carouselImages?.length > 1) ?? false;

  if (!assets?.length) {
    return <div data-testid={testId}>{children}</div>;
  }

  const hasSprinkledAssets = sprinkledAssets.length > 0;

  return (
    <>
      <FlexDiv data-testid={testId} className={className}>
        {children && <Content>{children}</Content>}
        {hasSprinkledAssets && (
          <Assets fullWidth={hasVideo}>
            {sprinkledAssets.map((ba, index) => (
              <AssetContainer asset={ba} key={`brand-asset-${index}`}>
                {!noTitles && <AssetTitle asset={ba.companyBrandAsset} />}
                <SprinkledAsset
                  brandAsset={ba}
                  carouselMode={carouselMode}
                  data-testid={`${testId}-asset`}
                />
              </AssetContainer>
            ))}
          </Assets>
        )}
      </FlexDiv>
      {carouselMode && <MobileCarousel cards={carouselImages ?? []} />}
    </>
  );
}
