import styled, { css, down } from "@xstyled/styled-components";
import { useLocation } from "react-router-dom";

import { FilterOptions, FilterTitles } from "./graph-helpers";

import { Button } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";

const ToggleContainer = styled.div`
  display: flex;
  gap: sm;
  height: ${pxToRem(40)};
  padding: xs;
  border: 1px solid ${palette.grayscale.shade200};
  border-radius: md;
  background-color: white;

  ${down(
    "tablet",
    css`
      height: ${pxToRem(32)};
    `
  )};

  &[data-on-job-card="true"] {
    gap: xs;
    height: ${pxToRem(32)};
  }
`;

const Toggle = styled(Button).attrs({ level: "tertiary" })`
  min-width: ${pxToRem(72)};
  padding: 6 lg;
  border: none;
  line-height: 100%;

  /* Trick to prevent the tabs to jump when we set the font-weight to bold, see https://github.com/WTTJ/otta/pull/858 */
  display: inline-block;
  &::after {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &[aria-selected="true"] {
    background-color: gray-100;
    font-weight: 600;
  }

  ${down(
    "tablet",
    css`
      font-size: ${pxToRem(13)};
    `
  )};

  &[data-on-job-card="true"] {
    padding: xs sm;
    text-wrap: nowrap;
  }
`;

export function FilterToggle({
  currentFilter,
  onChangeFilter,
  filters,
}: {
  currentFilter: FilterOptions;
  onChangeFilter: (filter: FilterOptions) => void;
  filters: FilterOptions[];
}) {
  const { pathname } = useLocation();
  const onJobCard = pathname.includes("/jobs");

  return (
    <ToggleContainer data-on-job-card={onJobCard}>
      {filters.map((filter: string) => (
        <Toggle
          data-on-job-card={onJobCard}
          key={`${filter}-toggle`}
          onClick={() => onChangeFilter(filter as FilterOptions)}
          aria-selected={currentFilter === filter}
          title={FilterTitles[filter as FilterOptions]}
          size={`${onJobCard ? "small" : "regular"}`}
        >
          {FilterTitles[filter as FilterOptions]}
        </Toggle>
      ))}
    </ToggleContainer>
  );
}
