import { useMemo } from "react";
import { x } from "@xstyled/styled-components";

import { Asset } from "../AssetSprinkler/Asset";
import { AssetSprinkler } from "../AssetSprinkler";
import { Tabs } from "../Tabs";

import { Spacing, Text } from "@otta/design";
import {
  BrandAssetType,
  BrandAssetWidth,
  CompanyProfileLinkFragment,
} from "@otta/search/schema";

// just a short alias for my sanity
type AssetLink = CompanyProfileLinkFragment;

/**
 * A bit nasty but if the first N assets are full-width videos
 * with titles fully set, we put them into tabs separately
 */
function splitVideos(assets: AssetLink[]): [AssetLink[], AssetLink[]] {
  const videos: AssetLink[] = [];
  const other: AssetLink[] = [];

  let currentCategory = videos;
  for (const asset of assets) {
    const isFullWidth =
      asset.desktopPlacement?.width === BrandAssetWidth.Full &&
      asset.mobilePlacement?.width === BrandAssetWidth.Full;
    const isVimeo = asset.companyBrandAsset.type === BrandAssetType.Vimeo;
    const hasTitles =
      !!asset.companyBrandAsset.shortTitle &&
      !!asset.companyBrandAsset.longTitle;

    if (!isFullWidth || !isVimeo || !hasTitles) {
      currentCategory = other;
    }

    currentCategory.push(asset);
  }

  // Ensure the videos category does not contain only one item
  if (videos.length === 1) {
    other.push(videos.pop() as AssetLink);
  }

  return [videos, other];
}

function VideoTab({ asset }: { asset: AssetLink }): React.ReactElement {
  return (
    <Spacing>
      <Text>{asset.companyBrandAsset.longTitle}</Text>
      <x.div display="flex" flexDirection="column" gap="sm">
        <Asset asset={asset} />
      </x.div>
    </Spacing>
  );
}

export function TeamAssets({
  assets,
}: {
  assets: CompanyProfileLinkFragment[];
}) {
  const [videos, other] = useMemo(() => splitVideos(assets), [assets]);
  const videoTabs = useMemo(
    () =>
      videos.map(video => ({
        title: `${video.companyBrandAsset.shortTitle}`,
        content: <VideoTab asset={video} />,
        id: `team-video-${video.id}`,
      })),
    [videos]
  );

  return (
    <>
      {!!videoTabs.length && <Tabs tabs={videoTabs} />}
      {!!other.length && <AssetSprinkler assets={other} />}
    </>
  );
}
