import { useState } from "react";
import styled, { css, up } from "@xstyled/styled-components";
import { Link } from "react-router-dom";

import {
  LocationOptions,
  PreferencesContent,
  SectorTagOptions,
} from "./PreferencesContent";

import { Text, Button, Spacing } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import {
  CurrentUserStatisticsDocument,
  CurrentUserDocument,
} from "@otta/search/schema";
import { Delete } from "@otta/search/components/Icons/Delete";
import { Overlay } from "@otta/search/components/Overlay";
import { Icon } from "@otta/icons";

const DesktopWrapper = styled.div`
  display: none;
  ${up(
    "tablet",
    css`
      display: block;
      width: 100%;
      max-width: 240px;
      margin-right: lg;
      flex-shrink: 0;
    `
  )}
`;

const DeleteWrapper = styled.div`
  position: absolute;
  inset: 0 0 0 auto;
  display: grid;
  place-items: center right;
  width: 48;

  svg {
    width: 16;
    margin-right: lg;
  }
`;

const Header = styled.div`
  position: relative;
  align-items: center;
  padding: lg;
  background-color: white;
`;

const FloatingMobileButton = styled.div`
  position: fixed;
  bottom: xl;
  right: xl;
  z-index: 1;
  display: grid;
  place-items: center;
  width: 44;
  height: 44;
  border-radius: 50%;
  background-color: yellow-500;
  ${up(
    "tablet",
    css`
      display: none;
    `
  )}
`;

const MobileOnlyOverlay = styled(Overlay)`
  max-width: unset;
  background-color: beige-200;
  ${up(
    "tablet",
    css`
      display: none;
    `
  )}
  z-index: 20;
`;

const MobileOnlyOverlayContent = styled.div`
  padding: lg;
`;

export interface IPreferencesContentProps {
  companyName: string;
  companySizes: string[];
  onChangeSearchBar: React.ChangeEventHandler;
  onClickCompanySize: (id: string) => React.ChangeEventHandler;
  onSelectSectorTag: (options: SectorTagOptions) => void;
  onSelectLocation: (options: LocationOptions) => void;
}

export function Preferences(
  props: IPreferencesContentProps
): React.ReactElement {
  const [showMobileOverlay, setShowMobileOverlay] = useState<boolean>(false);

  const { data } = useQuery(CurrentUserDocument);

  const { data: userData, loading } = useQuery(CurrentUserStatisticsDocument, {
    errorPolicy: "all",
  });
  const showSignupCta = !loading && !data?.currentUser;

  return (
    <>
      <DesktopWrapper>
        <Spacing size={2}>
          <PreferencesContent {...props} />
          {showSignupCta && (
            <Spacing>
              <Text as="h4" bold size={1}>
                {userData?.publicStatistics?.numberLiveCompanies.toLocaleString(
                  "en-GB"
                ) ?? "10,000"}{" "}
                companies hiring on Welcome to the Jungle
              </Text>
              <Button
                as={Link}
                to="/sign-up"
                level="primary"
                style={{
                  textDecoration: "none",
                  display: "inline-block",
                  width: "auto",
                }}
              >
                Sign up
              </Button>
            </Spacing>
          )}
        </Spacing>
      </DesktopWrapper>
      {showMobileOverlay && (
        <MobileOnlyOverlay>
          <Header>
            <Text as="h6" bold size={2}>
              Your search criteria
            </Text>
            <DeleteWrapper onClick={() => setShowMobileOverlay(false)}>
              <Delete />
            </DeleteWrapper>
          </Header>
          <MobileOnlyOverlayContent>
            <PreferencesContent {...props} />
          </MobileOnlyOverlayContent>
        </MobileOnlyOverlay>
      )}
      {!showMobileOverlay && (
        <FloatingMobileButton onClick={() => setShowMobileOverlay(true)}>
          <Icon icon="sliders" size={2} />
        </FloatingMobileButton>
      )}
    </>
  );
}
