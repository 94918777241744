import { useMemo } from "react";

import { IPreferencesContentProps } from "./Preferences";

import { Text, InputField, Spacing } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import {
  Location,
  CompanySizesDocument,
  TopSectorTagsDocument,
} from "@otta/search/schema";
import { SmallCheckbox } from "@otta/search/components/Input/SmallCheckbox";
import { Select } from "@otta/search/components/Input/Select";
import {
  AVAILABLE_CITIES,
  REMOTE_LOCATIONS_WITH_LABELS,
} from "@otta/search/utils/locations";

function CompanySize({
  preferences,
  handleClick,
}: {
  preferences: string[];
  handleClick: (id: string) => React.ChangeEventHandler;
}) {
  const { data, loading } = useQuery(CompanySizesDocument);

  const companySizes = data && data.companySizes ? data.companySizes : [];

  return (
    <Spacing size={-2}>
      {loading && (
        <SmallCheckbox
          label="..."
          onChange={() => {
            // do nothing, I'm here while loading
          }}
        />
      )}
      {companySizes.map(({ id, value }) => {
        const preference = preferences.find(sizeId => sizeId === id);
        const checked = preference ? true : false;
        return (
          <SmallCheckbox
            key={id}
            label={`${value} employees`}
            id={id}
            onChange={handleClick(id)}
            checked={checked}
          />
        );
      })}
    </Spacing>
  );
}
export type SectorTagOptions = {
  selectedSectorTags: string[];
};

function SectorTags({
  handleSelect,
}: {
  handleSelect: (options: SectorTagOptions) => void;
}) {
  const { data } = useQuery(TopSectorTagsDocument);
  const topSectorTags = useMemo(() => {
    const topSectorTags = data && data.topSectorTags ? data.topSectorTags : [];
    return topSectorTags.map(tag => ({
      label: tag.value,
      value: tag.id,
    }));
  }, [data]);

  return (
    <Select
      isMulti
      placeholder="Select an industry..."
      options={topSectorTags}
      onChange={options => {
        handleSelect({
          selectedSectorTags: options?.map(({ value }) => value) ?? [],
        });
      }}
    />
  );
}
export type LocationOptions = {
  selectedLocations: Location[];
};

function LocationSelect({
  handleSelect,
}: {
  handleSelect: (options: LocationOptions) => void;
}) {
  return (
    <Select
      isMulti
      placeholder="Select a location..."
      options={[...AVAILABLE_CITIES, ...REMOTE_LOCATIONS_WITH_LABELS]}
      onChange={options => {
        handleSelect({
          selectedLocations: options?.map(({ value }) => value) ?? [],
        });
      }}
    />
  );
}

export const PreferencesContent = ({
  companyName,
  onChangeSearchBar,
  onSelectLocation,
  companySizes,
  onClickCompanySize,
  onSelectSectorTag,
}: IPreferencesContentProps): React.ReactElement => (
  <Spacing size={3}>
    <Spacing size={-5}>
      <Text as="h6" bold>
        Search
      </Text>
      <InputField
        type="text"
        name="companyName"
        onChange={onChangeSearchBar}
        placeholder="Enter company name..."
        value={companyName}
      />
    </Spacing>
    <Spacing size={-5}>
      <Text as="h6" bold>
        Location
      </Text>
      <LocationSelect
        data-testid="company-location-preferences"
        handleSelect={onSelectLocation}
      />
    </Spacing>
    <Spacing size={-2}>
      <Text as="h6" bold>
        Company size
      </Text>
      <CompanySize
        preferences={companySizes}
        handleClick={onClickCompanySize}
      />
    </Spacing>
    <Spacing size={-5}>
      <Text as="h6" bold>
        Industries
      </Text>
      <SectorTags handleSelect={onSelectSectorTag} />
    </Spacing>
  </Spacing>
);
