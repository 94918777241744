import styled, { useViewportWidth, x } from "@xstyled/styled-components";
import { useMemo } from "react";

import { Filters } from "../CompaniesIndex";

import { MiniCompanyCard } from "./MiniCompanyCard";

import { Spacing, Text } from "@otta/design";
import { PersonalisedCompanies } from "@otta/search/schema";
import { pushAnalyticsEvent } from "@otta/analytics";

const TWO_COLUMNS_BREAKPOINT = 500;

const ListContainer = styled.div`
  display: grid;
  gap: xl;
  @media (min-width: ${TWO_COLUMNS_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export function CompaniesList({
  companies = [],
  filters,
}: {
  companies?: PersonalisedCompanies.PersonalisedCompanies[];
  filters: Filters;
}): React.ReactElement {
  const viewportWidth = useViewportWidth();

  // Split companies into two columns because native CSS masonry still isn't a thing
  const columns = useMemo(() => {
    if (viewportWidth && viewportWidth <= TWO_COLUMNS_BREAKPOINT) {
      return [companies];
    }

    return Array.from({ length: 2 }, (_, i) => {
      return companies.filter((_, index) => index % 2 === i);
    });
  }, [companies, viewportWidth]);

  const handleClickCompany = ({
    companyId,
    position,
    hasEBContent,
  }: {
    companyId: string;
    position: number;
    hasEBContent: boolean;
  }) => {
    pushAnalyticsEvent({
      eventName: "User Clicked Company",
      companyId,
      listPosition: position,
      brandedCompany: hasEBContent,
      ...filters,
    });
  };

  return (
    <Spacing>
      {companies.length > 0 ? (
        <ListContainer>
          {columns.map((column, columnIndex) => (
            <x.div
              key={columnIndex}
              display="flex"
              flexDirection="column"
              gap="xl"
            >
              {column.map((company, companyIndex) => (
                <MiniCompanyCard
                  key={company.id}
                  onClick={() => {
                    handleClickCompany({
                      companyId: company.id,
                      position: columnIndex + companyIndex * 2,
                      hasEBContent: company.brandAssetGroups?.length > 0,
                    });
                  }}
                  {...company}
                />
              ))}
            </x.div>
          ))}
        </ListContainer>
      ) : (
        <Text>No companies found</Text>
      )}
    </Spacing>
  );
}
