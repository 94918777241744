import styled, { x } from "@xstyled/styled-components";
import { differenceInDays, differenceInHours, parseISO } from "date-fns";

import { MeetTheTeamTag } from "./MeetTheTeamTag";

import { Text, Tooltip } from "@otta/design";
import { Icon } from "@otta/icons";
import { Company, CompanyWorkflowStatus, Job } from "@otta/search/schema";

const StatusTagsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const Tag = styled(x.div)`
  height: fit-content;
  width: fit-content;
  gap: sm;
  display: flex;
  border-radius: md;
  padding: xs sm;
`;

interface StatusTagsProps {
  job: Pick<
    Job.Fragment,
    "live" | "insertedAt" | "lastSeen" | "originalUrl" | "team"
  >;
  company: Pick<
    Company.Fragment,
    "onlyOnOtta" | "name" | "workflowStatus" | "teams"
  >;
}

export function StatusTags({
  job,
  company,
}: StatusTagsProps): React.ReactElement {
  const { live, insertedAt, lastSeen, originalUrl } = job;
  const { onlyOnOtta, workflowStatus } = company;

  return (
    <StatusTagsWrapper>
      <ApplicationStatus
        live={live}
        insertedAt={insertedAt}
        lastSeen={lastSeen}
        originalUrl={originalUrl}
        onlyOnOtta={onlyOnOtta}
      />
      <MeetTheTeamTag company={company} />
      {workflowStatus === CompanyWorkflowStatus.CompletedByUser && (
        <LightProfileTag />
      )}
    </StatusTagsWrapper>
  );
}

function ApplicationStatus({
  live,
  insertedAt,
  lastSeen,
  originalUrl,
  onlyOnOtta,
}: {
  live: boolean;
  insertedAt: string;
  lastSeen: string | null;
  originalUrl: string | null;
  onlyOnOtta: boolean;
}): React.ReactElement {
  const now = new Date();
  const postedAt = parseISO(insertedAt);
  const lastChecked = lastSeen ? parseISO(lastSeen) : now;
  const daysSincePosted = differenceInDays(now, postedAt);
  const hoursSinceChecked = differenceInHours(now, lastChecked);
  if (!live) {
    return (
      <Tag backgroundColor="red-200">
        <Icon icon="circle-close" size={1} />
        <Text bold>Job no longer available</Text>
      </Tag>
    );
  }

  const tagColor = live ? "green-200" : "red-200";
  if (!originalUrl) {
    return (
      <Tag backgroundColor={tagColor}>
        {daysSincePosted <= 1 ? (
          <Icon icon="new-to-otta" size={1} />
        ) : (
          <Icon icon="clock" size={1} />
        )}
        <Text bold>
          {daysSincePosted <= 1
            ? "Posted today"
            : daysSincePosted < 28
            ? "Be an early applicant"
            : "Open for applications"}
        </Text>
      </Tag>
    );
  }

  const content = onlyOnOtta
    ? `This job is checked every 24 hours.`
    : `We check jobs are live every few hours. This job was checked ${
        hoursSinceChecked < 1
          ? "less than 1 hour ago."
          : hoursSinceChecked === 1
          ? "1 hour ago."
          : `${hoursSinceChecked} hours ago.`
      }`;

  return (
    <Tooltip placement="bottom" content={content}>
      <Tag backgroundColor={tagColor}>
        {daysSincePosted <= 1 ? (
          <Icon icon="new-to-otta" size={1} />
        ) : (
          <Icon icon="clock" size={1} />
        )}
        <Text bold>
          {daysSincePosted <= 1
            ? "Posted today"
            : daysSincePosted < 28
            ? "Be an early applicant"
            : "Open for applications"}
        </Text>
      </Tag>
    </Tooltip>
  );
}

function LightProfileTag() {
  return (
    <Tooltip
      placement="bottom"
      content="This company is new and has provided basic information to get started. Check back later for more details."
    >
      <Tag backgroundColor="blue-200">
        <Icon icon="article" size={1} />
        <Text bold>New company</Text>
      </Tag>
    </Tooltip>
  );
}
