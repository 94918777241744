import { useState } from "react";
import styled from "@xstyled/styled-components";

import { Text } from "../Typography";
import { theme } from "../../theme";

import { FieldContainer } from "./FieldContainer";
import { Label } from "./Label";
import { Input } from "./Input";
import { Eye } from "./icons/EyeIcon";
import { EyeSlash } from "./icons/EyeSlashIcon";
import { Bullet } from "./icons/BulletIcon";
import { Cross } from "./icons/CrossIcon";
import { Check } from "./icons/CheckIcon";

import { palette } from "@otta/design-tokens";

/**
 *
 * ```ts
 *
 * import { PasswordField } from '@otta/design'
 *
 * ```
 */
export interface IPasswordFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  requirements?: {
    text: string;
    validate: (input?: string) => boolean;
  }[];
  name: string;
  placeholder?: string;
  error?: boolean;
  value?: string;
}
interface IRequirementProps {
  text: string;
  validated: boolean;
  error?: boolean;
}

const PasswordFieldContainer = styled.div`
  display: flex;
  align-items: stretch;
`;

const InputField = styled(Input)`
  border-radius: md 0 0 md;
  border-right: none;
`;

const ShowPasswordButton = styled.button`
  position: relative;
  background-color: gray-50;
  border-radius: 0 md md 0;
  padding: 0 lg;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border: 1px solid;
  border-color: gray-400;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: black;
  transition: default;
  &:disabled {
    background-color: gray-400;
  }
  &:hover {
    background-color: gray-100;
  }

  ${Input}:focus + & {
    border-color: black;
  }
  ${Input}[aria-invalid="true"] + & {
    box-shadow: 0 0 0 1px ${theme.colors["red-600"]};
    border-color: red-600;
  }
  &&:focus {
    box-shadow: 0 0 0 1px black;
    border-color: black;
    outline: none;
  }
`;

const Requirement = ({ text, validated, error }: IRequirementProps) => (
  <PasswordFieldContainer key={text}>
    {validated ? <Check /> : error ? <Cross /> : <Bullet />}
    <Text
      size={-1}
      color={
        validated
          ? palette.extended.green.shade600
          : error
          ? palette.brand.red
          : "black"
      }
    >
      {text}
    </Text>
  </PasswordFieldContainer>
);

export function PasswordField({
  label,
  requirements,
  name,
  error,
  placeholder,
  value,
  onChange,
  ...props
}: IPasswordFieldProps): React.ReactElement {
  const [show, setShow] = useState(false);

  return (
    <FieldContainer>
      {label && <Label htmlFor={name}>{label}</Label>}
      {requirements &&
        requirements.map(r => (
          <Requirement
            key={r.text}
            text={r.text}
            validated={r.validate(value)}
            error={error}
          />
        ))}
      <PasswordFieldContainer>
        <InputField
          id={name}
          name={name}
          type={show ? "text" : "password"}
          placeholder={placeholder}
          error={error}
          onChange={onChange}
          value={value}
          {...props}
        />
        <ShowPasswordButton
          name="Show or hide password"
          type="button"
          onClick={() => setShow(!show)}
        >
          {show ? <Eye /> : <EyeSlash />}
        </ShowPasswordButton>
      </PasswordFieldContainer>
    </FieldContainer>
  );
}
