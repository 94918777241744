import styled from "@xstyled/styled-components";
import { useEffect, useMemo, useState } from "react";

import { ShortlistCard } from "./ShortlistCard";
import { TipCard } from "./TipCard";
import { Section, CardsContainer, SpotlightCardsContainer } from "./Section";
import {
  isSpotlightJob,
  SpotlightShortlistCard,
} from "./SpotlightShortlistCard";

import { JobChangeHandler, Section as SectionType } from ".";

import { palette } from "@otta/design-tokens";
import { Button as DefaultButton, Text } from "@otta/design";
import { DashboardJobFragment } from "@otta/search/schema";
import { EBTrackingProvider } from "@otta/search/contexts/EBTracking";

const DEFAULT_CARD_LENGTH = 9;

const Button = styled(DefaultButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FilterButton = styled.button<{ selected: boolean | undefined }>`
  height: fit-content;
  background: ${({ selected }) =>
    selected ? palette.extended.yellow.shade100 : "none"};
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
  color: black;
  border: 1px solid ${palette.brand.black};
  -webkit-tap-highlight-color: transparent;
  border-radius: 4;
  padding: 4px 10px;
  cursor: pointer;
  transition: default;
  outline: none;
  margin-right: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 16px;
`;

const JobCount = styled(Text)`
  margin-left: 10px;
  margin-right: 10px;
  min-width: 50px;
  white-space: nowrap;
`;

const SectionInfoContainer = styled.div`
  // Negative margin to offset parent padding in mobile
  margin: 0 -10px;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SectionInfoContainerInner = styled.div`
  margin-right: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
`;

enum Filter {
  live = "LIVE",
  last7Days = "LAST_7_DAYS",
}

interface IFilterState {
  [Filter.live]?: boolean;
  [Filter.last7Days]?: boolean;
}

const getFilterFunction = (
  filter: Filter,
  val: boolean
): ((job: DashboardJobFragment) => boolean) => {
  switch (filter) {
    case Filter.live:
      return (job: DashboardJobFragment) => job.live === val;
    case Filter.last7Days:
      return (job: DashboardJobFragment) => {
        const lessThan8 = job.daysSincePosted < 8;
        return lessThan8 === val;
      };
  }
};

const applyFilters = (jobs: DashboardJobFragment[], filters: IFilterState) => {
  return Object.entries(filters).reduce(
    (acc, [key, val]) => acc.filter(getFilterFunction(key as Filter, val)),
    jobs
  );
};

const getInitialFilterState = (
  status: SectionType
): { [Filter.live]: boolean } | Record<string, never> => {
  return ["SAVED", "APPLICATION_STARTED"].includes(status)
    ? {
        [Filter.live]: true,
      }
    : {};
};

interface IShortlistSectionProps {
  title: string;
  section: SectionType;
  jobs: DashboardJobFragment[];
  onChange: JobChangeHandler;
  isMobile?: boolean;
}

export function ShortlistSection({
  title,
  jobs,
  section,
  onChange,
  isMobile,
}: IShortlistSectionProps): React.ReactElement {
  const [showAllCards, setShowAllCards] = useState(false);
  const [filter, setFilter] = useState<IFilterState>(
    getInitialFilterState(section)
  );

  useEffect(() => {
    setFilter(getInitialFilterState(section));
  }, [section]);

  const inactiveJobsLength = useMemo(
    () =>
      ["SAVED", "APPLICATION_STARTED"].includes(section)
        ? jobs.filter(j => !j.live).length
        : 0,
    [jobs, section]
  );

  const processedJobs = useMemo(
    () => applyFilters(jobs, filter),
    [filter, jobs]
  );

  const spotlightJobs = useMemo(() => {
    return processedJobs.filter(isSpotlightJob);
  }, [processedJobs]);

  const otherJobs = useMemo(() => {
    if (!spotlightJobs.length) {
      return processedJobs;
    }
    return (processedJobs || []).filter(j => spotlightJobs.indexOf(j) < 0);
  }, [spotlightJobs, processedJobs]);

  const toggleShowAllCards = () => {
    setShowAllCards(!showAllCards);
  };

  const toggleOnOffFilter = (f: Filter) => {
    if (filter[f]) {
      const newFilter = { ...filter };
      delete newFilter[f];
      setFilter(newFilter);
    } else {
      setFilter({ ...filter, [f]: true });
    }
  };

  const toggleTrueFalseFilter = (f: Filter) => {
    if (f in filter) {
      setFilter({ ...filter, [f]: !filter[f] });
    }
  };

  return (
    <Section title={title}>
      <SectionInfoContainer>
        <SectionInfoContainerInner>
          <JobCount data-testid="section-job-count">
            {processedJobs.length >= 0 &&
              `${processedJobs.length} job${
                processedJobs.length > 1 || processedJobs.length === 0
                  ? "s"
                  : ""
              }`}
          </JobCount>
          <FilterButton
            selected={filter.LAST_7_DAYS}
            onClick={() => toggleOnOffFilter(Filter.last7Days)}
          >
            Posted in last 7 days
          </FilterButton>
          {["SAVED", "APPLICATION_STARTED"].includes(section) && (
            <FilterButton
              selected={!filter.LIVE}
              onClick={() => toggleTrueFalseFilter(Filter.live)}
            >
              Inactive jobs ({inactiveJobsLength})
            </FilterButton>
          )}
        </SectionInfoContainerInner>
      </SectionInfoContainer>

      {spotlightJobs.length > 0 && (
        <SpotlightCardsContainer>
          {spotlightJobs.map(job => (
            <EBTrackingProvider key={job.id} company={job.company} job={job}>
              <SpotlightShortlistCard
                jobId={job.externalId}
                company={job.company}
                section={section}
                onChange={onChange}
                isMobile={isMobile}
              />
            </EBTrackingProvider>
          ))}
        </SpotlightCardsContainer>
      )}

      <CardsContainer>
        {otherJobs.length > 0 ? (
          <>
            {isMobile ? (
              otherJobs.map(job => (
                <ShortlistCard
                  key={job.id}
                  job={job}
                  section={section}
                  onChange={onChange}
                />
              ))
            ) : (
              <>
                {otherJobs.slice(0, DEFAULT_CARD_LENGTH).map(job => (
                  <ShortlistCard
                    key={job.id}
                    job={job}
                    section={section}
                    onChange={onChange}
                  />
                ))}
                {showAllCards &&
                  otherJobs
                    .slice(DEFAULT_CARD_LENGTH)
                    .map(job => (
                      <ShortlistCard
                        key={job.id}
                        job={job}
                        section={section}
                        onChange={onChange}
                      />
                    ))}
              </>
            )}
            {processedJobs.length < DEFAULT_CARD_LENGTH && (
              <TipCard sectionType={section} tipType="TIP" />
            )}
          </>
        ) : !filter.LIVE || filter.LAST_7_DAYS ? (
          <TipCard sectionType={section} tipType="FILTER_NO_RESULTS" />
        ) : (
          <TipCard sectionType={section} tipType="EMPTY" />
        )}
      </CardsContainer>
      {!isMobile && otherJobs.length > DEFAULT_CARD_LENGTH && (
        <Button
          level="secondary"
          onClick={toggleShowAllCards}
          data-testid="show-more"
        >
          {showAllCards
            ? "Show less"
            : `Show remaining ${otherJobs.length - DEFAULT_CARD_LENGTH}`}
        </Button>
      )}
    </Section>
  );
}
