import styled, { css } from "@xstyled/styled-components";

import { theme } from "../../theme";

import { modularScale } from "@otta/design-tokens";

interface IInputProps {
  error?: boolean;
}

export const Input = styled.input<IInputProps>(
  ({ error }) => css`
    display: block;
    width: 100%;
    border-radius: 4;
    font-size: ${modularScale()};
    font-family: inherit;
    padding: sm md;
    border: 1px solid;
    border-color: gray-400;
    transition: default;
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ${error &&
    css`
      box-shadow: 0 0 0 1px ${theme.colors["red-600"]};
      border-color: red-600;
    `}

    &[aria-invalid="true"] {
      box-shadow: 0 0 0 1px ${theme.colors["red-600"]};
      border-color: red-600;
    }

    &:focus {
      box-shadow: 0 0 0 1px black;
      border-color: black;
      outline: none;
    }

    ::placeholder {
      color: gray-400;
    }
  `
);
