import { useMutation } from "@apollo/client";
import { useEffect, ReactElement, useState, useCallback, useMemo } from "react";
import { AnimatePresence } from "framer-motion";
import styled, { useDown } from "@xstyled/styled-components";
import { useLocation } from "react-router-dom";

import { batchName, getThemeId, BatchOptions, toSlug } from "../BatchOptions";
import { compareJobs, Section } from "../../../Dashboard";
import { ShortlistCard } from "../../../Dashboard/ShortlistCard";
import { handleMutationError } from "../../../../utils/error";

import Preferences from "./components/Preferences";
import ReviewPreferences from "./components/ReviewPreferences";
import NextSteps from "./components/NextSteps";
import {
  firstName,
  ignoreSubfunctions,
  NextStep,
  ReplaceThemedBatchCache,
  StepType,
  suggestions,
  Suggestions,
  useNextStep,
} from "./actions/NextStep";
import { ThemedBatches } from "./components/ThemedBatches";
import { BackLink } from "./components/BackLink";
import { Resubscribe } from "./Resubscribe";

import { pxToRem } from "@otta/design-tokens";
import {
  Heading,
  Text,
  Middle,
  responsive,
  PageProgressIndicator,
  Button,
} from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  DashboardJobFragment,
  DismissJobPreferenceSuggestionDocument,
  BatchEndShortlistedJobsDocument,
  UserJobStatus,
  UpdateShortlistUserJobStatusDocument,
  Notification,
  UserNotificationDocument,
  BatchEndShortlistedJobs,
} from "@otta/search/schema";
import { useJobRecommendations } from "@otta/search/pages/Jobs/JobRecommendationProvider";
import {
  isSpotlightJob,
  SpotlightShortlistCard,
} from "@otta/search/pages/Dashboard/SpotlightShortlistCard";
import { Link } from "@otta/search/components/Link";
import { addPossessiveSuffix } from "@otta/search/utils/strings";
import { EBTrackingProvider } from "@otta/search/contexts/EBTracking";

export { BatchEndTop } from "./BatchEndTop";

interface BatchEndProps {
  token?: string;
  params: BatchOptions;
  jobCount: number;
  previousJob?: string;
}

enum BatchState {
  CompletelyOutOfJobs = "CompletelyOutOfJobs",
  JobsAvailableOutsideSubfunction = "JobsAvailableOutsideSubfunction",
  MoreJobsAvailable = "MoreJobsAvailable",
}

const LoadingWrapper = styled.div`
  align-items: center;
  position: absolute;
  inset: 0 0 0 0;
  display: flex;
`;

const HideWhenLoading = styled.div<{ $loading: boolean }>`
  visibility: ${p => (p.$loading ? "hidden" : "visible")};
`;

const BatchEndHeading = styled(Heading)`
  ${responsive`font-size: ${responsive.modularScale({
    mobile: 3,
    desktop: 4,
  })};`}
  ${responsive`margin-top: ${{ mobile: 0, desktop: 26 }};`}
  line-height: ${pxToRem(43)};
`;

const BetterMatchesText = styled(Text)`
  ${responsive`font-size: ${responsive.modularScale({
    mobile: 1,
    desktop: 2,
  })};`}
  ${responsive`margin-top: ${{ mobile: 16, desktop: 40 }};`}
  margin-bottom: lg;
  font-weight: 600;
`;
const TopContainer = styled.div`
  position: relative;
  background-color: beige-100;
  padding: 0 lg 3xl lg;
`;

const SuggestionContainer = styled.div`
  padding: 0 lg;
`;

const NavigationContainer = styled.div`
  padding: lg 0;
`;

const Subtitle = styled.p`
  ${responsive`font-size: ${responsive.modularScale({
    mobile: 0,
    desktop: 1,
  })};`}
  ${responsive`padding: ${{ mobile: 8, desktop: 16 }} 0;`}
`;

const SpotlightShortlist = styled.div`
  display: flex;
  flex-direction: column;
  gap: xl;
  max-width: 100%;
  margin-top: 40;
`;

const SpotlightShortlistHeader = styled.div`
  display: flex;
  align-items: center;
  gap: xl;
`;

const SpotlightShortlistHeaderTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: lg;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: lg;
  row-gap: md;

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
    row-gap: lg;
  }

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

function hasBatches(ns: NextStep): ns is ReplaceThemedBatchCache {
  return (
    ns.type === StepType.RefreshRecommendations ||
    ns.type === StepType.ReplaceThemedBatchCache ||
    (ns.type === StepType.GoBackToDashboard &&
      ns.themedBatches !== undefined &&
      ns.topSector !== undefined)
  );
}

function findSuggestions(
  nextStep?: NextStep
): Suggestions["suggestions"] | undefined {
  return suggestions(nextStep)?.suggestions.filter(s =>
    [
      "DislikedSectorPreferenceSuggestion",
      "DislikedTechnologyPreferenceSuggestion",
    ].includes(s.__typename)
  );
}

function Theme({ theme }: { theme: string | null }) {
  return theme ? <strong>{theme}</strong> : null;
}

function SubTitleText({
  themeName,
  nextState,
}: {
  themeName: string | null;
  nextState?: BatchState;
}): ReactElement {
  const theme = <Theme theme={themeName} />;
  return (
    <Subtitle>
      {(() => {
        switch (nextState) {
          case BatchState.MoreJobsAvailable:
            return <>You&apos;ve seen another set of {theme} matches</>;
          case BatchState.JobsAvailableOutsideSubfunction:
            return (
              <>
                There are no more matches right now, but we&apos;ve found some
                matches outside of your preferences
              </>
            );
          default: {
            return <> You&apos;ve seen all your {theme} matches </>;
          }
        }
      })()}
    </Subtitle>
  );
}

function Title({ nextState }: { nextState?: BatchState }): ReactElement {
  return (
    <BatchEndHeading>
      {nextState !== BatchState.MoreJobsAvailable ? (
        <>You&apos;re all caught up!</>
      ) : (
        <>Great progress!</>
      )}
    </BatchEndHeading>
  );
}

const CompanyProfileButtonWrapper = styled(Button).attrs({
  level: "secondary",
})`
  flex-shrink: 0;
  &:hover {
    text-decoration: none;
  }
`;

function CompanyProfileButton({ companyName }: { companyName: string }) {
  return (
    <CompanyProfileButtonWrapper
      as={Link}
      to={`/companies/${encodeURIComponent(companyName)}`}
      data-analytics-id="batch-end-company-profile"
    >
      Company profile
    </CompanyProfileButtonWrapper>
  );
}

const nextBatchState = (nextStep: NextStep): BatchState => {
  switch (nextStep?.type) {
    case StepType.GoBackToDashboard:
      return BatchState.CompletelyOutOfJobs;
    case StepType.ReplaceThemedBatchCache:
      return BatchState.MoreJobsAvailable;
    case StepType.RefreshRecommendations:
      return nextStep.ignoreSubfunctions
        ? BatchState.JobsAvailableOutsideSubfunction
        : BatchState.MoreJobsAvailable;
  }
};

export function BatchEnd({
  token,
  jobCount,
  previousJob,
  params,
}: BatchEndProps): ReactElement {
  const location = useLocation();

  const { skip } = useJobRecommendations();

  const { data: shortlistedJobsData } = useQuery(
    BatchEndShortlistedJobsDocument,
    {
      fetchPolicy: "cache-and-network",
      context: { emailToken: token },
    }
  );

  const {
    data: notification,
    loading: resubLoading,
    refetch,
  } = useQuery(UserNotificationDocument, {
    variables: { pageType: "batch_end" },
    context: { emailToken: token },
  });

  const loginStatus = token ? "email-token" : "logged-in";

  const [suggestionIdx, setSuggestionIdx] = useState(0);

  const [nextStep, apply] = useNextStep(params, token);

  const suggestions = findSuggestions(nextStep);
  const hasSuggestions = !!suggestions?.length;
  const themeName = batchName(params);

  const shouldFireSubfunctionEvent = ignoreSubfunctions(nextStep);

  useEffect(() => {
    if (shouldFireSubfunctionEvent) {
      pushAnalyticsEvent({
        eventName: "Candidate Shown",
        pathname: location.pathname,
        name: "Matches Outside Subfunctions Button",
      });
    }
  }, [location.pathname, shouldFireSubfunctionEvent]);

  const [dismissSuggestion] = useMutation(
    DismissJobPreferenceSuggestionDocument,
    { errorPolicy: "all" }
  );

  const [updateJobStatusMutation] = useMutation(
    UpdateShortlistUserJobStatusDocument,
    {
      optimisticResponse: ({ jobId, status }) => ({
        updateShortlistUserJobStatus: {
          id: jobId,
          userJobStatusInfo: {
            __typename: "UserJobStatusInfo" as const,
            status,
            updatedAt: new Date().toISOString(),
          },
          __typename: "Job" as const,
        },
      }),
      onError: handleMutationError,
    }
  );

  const updateJobStatus = useCallback(
    async (job: DashboardJobFragment, newSection: Section) => {
      if (newSection === "DISCARDED") {
        skip(job);
      } else {
        await updateJobStatusMutation({
          variables: {
            jobId: job.id,
            status: newSection as UserJobStatus,
          },
        });
      }
    },
    [updateJobStatusMutation, skip]
  );

  const handleRefresh = (seeMoreJobs = false) => {
    const suggestion = suggestions?.[suggestionIdx];
    if (suggestion) {
      dismissSuggestion({ variables: { id: suggestion.id } });
      setSuggestionIdx(suggestionIdx + 1);
    }

    if (seeMoreJobs) {
      pushAnalyticsEvent({
        eventName: "Candidate Clicked",
        name: "see-more-jobs",
        pathname: location.pathname,
        loginStatus,
      });
    }

    apply();
  };
  const nextState = nextStep ? nextBatchState(nextStep) : undefined;
  const loading = nextStep === undefined || nextState === undefined;

  const { savedLiveJobs, savedSpotlightLiveJobs } = useMemo(() => {
    const shortlistedJobs =
      shortlistedJobsData?.currentUser?.shortlistedJobs ?? [];
    let savedLiveJobs: BatchEndShortlistedJobs.ShortlistedJobs[] = [];
    const savedSpotlightLiveJobs: Map<
      string,
      BatchEndShortlistedJobs.ShortlistedJobs[]
    > = new Map();

    for (const job of shortlistedJobs) {
      if (!job.live || job.userJobStatusInfo.status !== UserJobStatus.Saved) {
        continue;
      }
      if (isSpotlightJob(job)) {
        const item = savedSpotlightLiveJobs.get(job.company.id) || [];
        savedSpotlightLiveJobs.set(job.company.id, [...item, job]);
      } else {
        savedLiveJobs.push(job);
      }
    }
    savedLiveJobs = savedLiveJobs.sort(compareJobs).slice(0, 6);

    return {
      savedLiveJobs,
      savedSpotlightLiveJobs,
    };
  }, [shortlistedJobsData]);

  const themeSlug =
    params.type !== "none" ? toSlug(params.theme) : "all-matches";

  const handleAnalyticsProperties = (
    nextState: BatchState,
    hasSavedJobs: boolean,
    suggestions: Suggestions["suggestions"] | undefined,
    notification: Notification | null
  ): string[] => {
    const array: string[] = [];

    switch (nextState) {
      case BatchState.MoreJobsAvailable:
        array.push("seeMoreJobs");
        break;
      case BatchState.JobsAvailableOutsideSubfunction:
        array.push("exploreBeyondSubfunctionPreferences");
        break;
      case BatchState.CompletelyOutOfJobs:
        array.push(hasSavedJobs ? "reviewSavedJobs" : "caughtUpDeadEnd");
        break;
    }
    if (suggestions?.length) {
      array.push(
        suggestions[0].__typename === "DislikedTechnologyPreferenceSuggestion"
          ? "technologyPreferenceSuggestions"
          : "sectorPreferenceSuggestions"
      );
    } else {
      array.push("updatePreferences");
    }
    if (notification === Notification.BatchEndResubscribe) {
      array.push("email-frequency");
    }

    return array;
  };

  useEffect(() => {
    if (nextState !== undefined) {
      const analyticsEventProperties = handleAnalyticsProperties(
        nextState,
        savedLiveJobs.length > 0,
        suggestions,
        notification?.userNotification ?? null
      );

      pushAnalyticsEvent({
        eventName: "Candidate Viewed Batch End Card",
        properties: analyticsEventProperties,
        themeId: themeSlug,
        loginStatus,
      });
    }
  }, [
    loginStatus,
    nextState,
    notification?.userNotification,
    savedLiveJobs.length,
    suggestions,
    themeSlug,
  ]);

  const isMobile = useDown("tablet");

  const hasSavedJobs =
    savedLiveJobs.length > 0 || savedSpotlightLiveJobs.size > 0;

  return (
    <>
      <TopContainer>
        <Middle maxWidth={1024} textAlign="left">
          <NavigationContainer>
            {previousJob && <BackLink to={`../${previousJob}`} />}
            <PageProgressIndicator current={jobCount} total={jobCount} />
          </NavigationContainer>
          {loading && (
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
          )}
          <HideWhenLoading $loading={loading}>
            <Title nextState={nextState} />
            <SubTitleText themeName={themeName} nextState={nextState} />
            <NextSteps
              nextStep={nextStep}
              onRefresh={handleRefresh}
              token={token}
            />
          </HideWhenLoading>
        </Middle>
      </TopContainer>
      {notification?.userNotification === Notification.BatchEndResubscribe &&
        !resubLoading && <Resubscribe token={token} refetch={refetch} />}
      {!loading && (
        <>
          {hasSavedJobs ? (
            <SuggestionContainer>
              <Middle maxWidth={1024} textAlign="left">
                {Array.from(savedSpotlightLiveJobs.values()).map(jobs =>
                  jobs.map((job, index) => (
                    <SpotlightShortlist key={job.id}>
                      {index === 0 && (
                        <SpotlightShortlistHeader>
                          <SpotlightShortlistHeaderTexts>
                            <Text bold size={2}>
                              You saved {jobs.length === 1 ? "a job" : "jobs"}{" "}
                              at {job.company.name}
                            </Text>
                            <Text size={1}>
                              Check out {addPossessiveSuffix(job.company.name)}{" "}
                              team or view their mission, values and more on
                              their company profile.
                            </Text>
                          </SpotlightShortlistHeaderTexts>
                          {!isMobile && (
                            <CompanyProfileButton
                              companyName={job.company.urlSafeName}
                            />
                          )}
                        </SpotlightShortlistHeader>
                      )}
                      <EBTrackingProvider company={job.company} job={job}>
                        <SpotlightShortlistCard
                          jobId={job.externalId}
                          company={job.company}
                          section={UserJobStatus.Saved}
                          onChange={({ newSection }) =>
                            updateJobStatus(job, newSection)
                          }
                          isMobile={isMobile}
                          isBatchEnd
                        />
                      </EBTrackingProvider>
                      {isMobile && (
                        <CompanyProfileButton
                          companyName={job.company.urlSafeName}
                        />
                      )}
                    </SpotlightShortlist>
                  ))
                )}
                {savedLiveJobs.length > 0 && (
                  <>
                    <BetterMatchesText>
                      Saved jobs that are still hiring
                    </BetterMatchesText>
                    <CardsContainer>
                      {savedLiveJobs.map(job => (
                        <ShortlistCard
                          key={job.id}
                          job={job}
                          section={UserJobStatus.Saved}
                          onChange={({ newSection }) =>
                            updateJobStatus(job, newSection)
                          }
                          isBatchEnd
                        />
                      ))}
                    </CardsContainer>
                  </>
                )}
              </Middle>
            </SuggestionContainer>
          ) : (
            hasBatches(nextStep) && (
              <ThemedBatches
                batches={nextStep.themedBatches}
                currentTheme={getThemeId(params) ?? "ALL_MATCHES"}
                sector={nextStep.topSector}
              >
                <BetterMatchesText>Explore more matches</BetterMatchesText>
              </ThemedBatches>
            )
          )}

          <SuggestionContainer>
            <Middle maxWidth={1024} textAlign="left">
              <BetterMatchesText>Get better matches</BetterMatchesText>
              <AnimatePresence initial={false}>
                {hasSuggestions && (
                  <Preferences
                    suggestionIdx={suggestionIdx}
                    suggestions={suggestions}
                    onNext={() => setSuggestionIdx(suggestionIdx + 1)}
                    firstName={firstName(nextStep)}
                  />
                )}
              </AnimatePresence>
              {!hasSuggestions && <ReviewPreferences />}
            </Middle>
          </SuggestionContainer>
        </>
      )}
    </>
  );
}
