import styled from "@xstyled/styled-components";

import { Text } from "../Typography";
import { theme } from "../../theme";

import { DefaultCheckmark } from "./style/Checkmark";

import { palette, pxToRem } from "@otta/design-tokens";

const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

interface CheckMarkProps {
  disabled?: boolean;
}

const Checkmark = styled(DefaultCheckmark)<CheckMarkProps>`
  left: 0;
  height: 20;
  width: 20;
  border-radius: md;
  background-color: ${({ disabled }) =>
    disabled ? palette.brand.black : palette.brand.grey};
`;

const Label = styled.label<CheckMarkProps>`
  display: block;
  position: relative;
  user-select: none;
  padding-left: 28;
  margin: sm 0;
  cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
  color: black;

  ${Input} + ${Checkmark} {
    background-color: gray-200;
    transition: default;
  }

  ${Input}:hover + ${Checkmark} {
    background-color: gray-400;
  }

  ${Input}:checked + ${Checkmark} {
    background-color: ${({ disabled }) =>
      disabled ? palette.grayscale.shade200 : palette.brand.yellow};

    &:after {
      opacity: 1;
    }
  }

  ${Input}:focus-visible + ${Checkmark} {
    box-shadow: 0 0 0 2px white, 0 0 0 4px ${theme.colors["yellow-500"]};
  }

  ${Checkmark}:after {
    display: block;
    left: 7;
    top: 3;
    width: 4;
    height: 9;
    border: solid
      ${({ disabled }) =>
        disabled ? `${palette.grayscale.shade200}` : `${palette.brand.black}`};
    border-width: 0 ${pxToRem(2)} ${pxToRem(2)} 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: fast;
  }
`;

export interface ICheckboxProps extends CheckMarkProps {
  label: React.ReactNode;
  name?: string;
  value?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  className?: string;
}

/**
 * ```ts
 *
 * import { Checkbox } from '@otta/design'
 *
 * ```
 */

export const Checkbox = ({
  label,
  name,
  value,
  checked,
  onChange,
  disabled,
  className,
}: ICheckboxProps) => (
  <Label disabled={disabled} className={className}>
    {typeof label === "string" ? (
      <Text as="span" size={-1} align="left">
        {label}
      </Text>
    ) : (
      label
    )}
    <Input
      type="checkbox"
      name={name}
      disabled={disabled}
      value={value}
      checked={checked}
      onChange={
        onChange &&
        (e => {
          onChange(e.target.checked);
        })
      }
    />
    <Checkmark disabled={disabled} />
  </Label>
);
