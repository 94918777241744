import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { Question, Quiz } from "@otta/search/containers/Quiz";
import {
  UK_LOCATIONS,
  EU_LOCATIONS,
  US_LOCATIONS,
} from "@otta/search/constants/locations";
import { PopularTechnologiesUsedDocument } from "@otta/search/schema";
import { CompanyQuality } from "@otta/search/containers/Quiz/questions/CompanyQuality";
import { CompanySizePreference } from "@otta/search/containers/Quiz/questions/CompanySizePreference";
import { Demographic } from "@otta/search/containers/Quiz/questions/Demographic";
import { EmailNotifications } from "@otta/search/containers/Quiz/questions/EmailNotifications";
import { JobExperience } from "@otta/search/containers/Quiz/questions/JobExperience";
import { JobFunctionSubFunction } from "@otta/search/containers/Quiz/questions/JobFunctionSubFunction";
import { LanguageRequirement } from "@otta/search/containers/Quiz/questions/LanguageRequirement";
import { LinkedinUrl } from "@otta/search/containers/Quiz/questions/LinkedinUrl";
import { Location } from "@otta/search/containers/Quiz/questions/Location";
import { NegativeTechnologiesPreference } from "@otta/search/containers/Quiz/questions/NegativeTechnologiesPreference";
import { PositiveTechnologiesPreference } from "@otta/search/containers/Quiz/questions/PositiveTechnologiesPreference";
import { SalaryPreference } from "@otta/search/containers/Quiz/questions/SalaryPreference";
import { SearchStage } from "@otta/search/containers/Quiz/questions/SearchStage";
import { PositiveSectorTagsPreference } from "@otta/search/containers/Quiz/questions/PositiveSectorTagsPreference";
import { NegativeSectorTagsPreference } from "@otta/search/containers/Quiz/questions/NegativeSectorTagsPreference";
import { VisaSponsorship } from "@otta/search/containers/Quiz/questions/VisaSponsorship";
import { useUserPreferences } from "@otta/search/providers/UserPreferencesProvider/useUserPreferences";
import { SignUp } from "@otta/search/containers/Quiz/questions/SignUp";
import { SignUpReason } from "@otta/search/containers/Quiz/questions/SignUpReason";
import { RemoteLocation } from "@otta/search/containers/Quiz/questions/RemoteLocation";
import { CurrentAddress } from "@otta/search/containers/Quiz/questions/CurrentAddress";
import { useExperiment } from "@otta/search/hooks/unleash";
import { Experiment } from "@otta/search/constants/experiments";

interface IOptionalQuestionOptions {
  showTechnologies: boolean;
  loggedIn: boolean;
}

const baseQuestions = (
  onlyRemoteOpportunities: boolean,
  multipleLocations: boolean,
  currentAddressExperimentEnabled: boolean
): Question[] => {
  const questionsToRender: (false | Question)[] = [
    {
      title: "Which 3 are most important to you in a new role?",
      component: CompanyQuality,
      path: "quality",
    },
    currentAddressExperimentEnabled && {
      title: "Where do you live?",
      subtitle: "This will help us personalise your experience",
      component: CurrentAddress,
      path: "current-location",
    },
    {
      title: "Where would you like to work?",
      component: Location,
      path: "location",
    },
    onlyRemoteOpportunities && {
      title: "Where could you work remotely from?",
      component: RemoteLocation,
      path: "remote-location",
    },
    {
      title: multipleLocations
        ? "Do you require a visa to work in these locations?"
        : `Do you require a visa to work in this location?`,
      component: VisaSponsorship,
      path: "visa-sponsorship",
    },
    {
      title: "Which languages can you work in?",
      component: LanguageRequirement,
      path: "language-requirement",
    },
    {
      title: "When are you looking to start your new job?",
      component: SearchStage,
      path: "search-stage",
    },
    {
      title: "What types of roles would you like to see?",
      component: JobFunctionSubFunction,
      path: "function",
    },
    {
      title: "What level of roles would you like to see?",
      component: JobExperience,
      subtitle: "Select the most relevant for you (max 2)",
      path: "job-experience",
    },
  ];

  return questionsToRender.filter((question): question is Question =>
    Boolean(question)
  );
};

const questions = ({
  showTechnologies,
  loggedIn,
}: IOptionalQuestionOptions): Question[] => {
  const questionsToRender: (false | Question)[] = [
    {
      title: "Do you want to work for a specific size of company?",
      component: CompanySizePreference,
      path: "company-size",
    },
    {
      title: "Do you have any favourite industries in mind?",
      component: PositiveSectorTagsPreference,
      path: "positive-company-sectors",
    },
    {
      title: "Are there any industries you don't want to work in?",
      component: NegativeSectorTagsPreference,
      path: "negative-company-sectors",
    },
    {
      title: "Do you have any favourite technologies in mind?",
      component: PositiveTechnologiesPreference,
      path: "positive-technologies",
      skip: !showTechnologies,
    },
    {
      title: "Are there any technologies you don't want to use at work?",
      component: NegativeTechnologiesPreference,
      path: "negative-technologies",
      skip: !showTechnologies,
    },
    {
      title: "What is your minimum expected salary?",
      component: SalaryPreference,
      path: "salary",
    },
    {
      title: "More about you (optional)",
      component: Demographic,
      path: "demographic",
    },
    {
      title: "One more thing...",
      subtitle: "View, save, and apply for jobs by creating an account",
      component: SignUp,
      path: "sign-up",
      skip: loggedIn,
    },
    {
      title:
        "Would you like to be contacted directly by companies that match your preferences?",
      component: LinkedinUrl,
      path: "linkedin-profile",
    },
    {
      title: "When would you like to be emailed about new jobs?",
      component: EmailNotifications,
      path: "email-notifications",
    },
    {
      title: "Where did you hear about us?",
      component: SignUpReason,
      path: "signup-reason",
    },
  ];

  return questionsToRender.filter((question): question is Question =>
    Boolean(question)
  );
};

export default function InitialPreferences(): React.ReactElement {
  const { variant } = useExperiment(Experiment.CurrentAddress);
  const currentAddressExperimentEnabled = variant === "variant";

  const navigate = useNavigate();
  const { preferencesData, loggedIn, onlyRemoteOpportunities } =
    useUserPreferences();

  const {
    data: popularTechnologiesData,
    previousData: previousPopularTechnologiesData,
  } = useQuery(PopularTechnologiesUsedDocument, {
    skip: !preferencesData,
    variables: {
      subFunctions: (preferencesData?.jobSubFunctionPreferences ?? []).map(
        pref => pref.subFunctionId
      ),
    },
  });

  const locationPreferences = useMemo(
    () => (preferencesData?.locationPreferences ?? []).map(l => l.location),
    [preferencesData?.locationPreferences]
  );

  if (!preferencesData) {
    return <Loading />;
  }

  const showTechnologies =
    (
      popularTechnologiesData?.popularTechnologiesUsed ??
      previousPopularTechnologiesData?.popularTechnologiesUsed ??
      []
    ).length > 5;

  const multipleLocations =
    [
      locationPreferences.find(location => UK_LOCATIONS.has(location)),
      locationPreferences.find(location => US_LOCATIONS.has(location)),
      locationPreferences.find(location => EU_LOCATIONS.has(location)),
    ].filter(location => location).length > 1;

  const handleFinish = () => {
    localStorage.setItem("first_visit", "true");
    navigate("/jobs", { replace: true, state: { onboard: true } });
  };

  const restOfQuestions = questions({
    showTechnologies,
    loggedIn,
  });

  const questionsToRender = [
    ...baseQuestions(
      onlyRemoteOpportunities,
      multipleLocations,
      currentAddressExperimentEnabled
    ),
    ...restOfQuestions,
  ];

  return (
    <Quiz
      questions={questionsToRender}
      finishText="Finish"
      analyticsName="initial-preferences"
      data-testid="initial-preferences-quiz"
      onFinish={handleFinish}
      animated
      progressBanner
    />
  );
}
