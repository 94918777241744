import { Helmet } from "react-helmet-async";
import { Route, Routes, useLocation } from "react-router-dom";
import styled from "@xstyled/styled-components";
import { useEffect, useRef } from "react";
import { useMutation } from "@apollo/client";

import Dashboard from "./pages/Dashboard";
import Salaries from "./pages/Salaries";
import Profile from "./pages/Profile";
import Messaging from "./pages/Messaging";
import InitialPreferences from "./pages/Preferences/InitialPreferences";
import JobPreferences from "./pages/Preferences/JobPreferences";
import ShortlistJob from "./pages/Dashboard/ShortlistJob";
import EmployeeSurveySignUpPage from "./pages/Companies/EmployeeSurveySignUpPage";
import EmployeeSurveyLandingPage from "./pages/Companies/EmployeeSurveyLandingPage";
import { ProfilePreferences } from "./pages/Profile/Preferences";
import { ProfileImport } from "./pages/Profile/Import";
import { JobContent } from "./pages/Jobs/JobCard/tabs/JobContent";
import { CompanyProfile } from "./pages/Jobs/JobCard/tabs/CompanyProfile";
import { SitemapIndex } from "./pages/CompaniesSitemap";

import { GlobalStyles } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { PageWithNavBar } from "@otta/search/components/NavBar";
import { AccountSettings } from "@otta/search/pages/AccountSettings";
import { DeleteMyAccount } from "@otta/search/pages/DeleteMyAccount";
import { EmployeeSurvey } from "@otta/search/pages/EmployeeSurvey";
import { ForgotPassword } from "@otta/search/pages/ForgotPassword";
import Jobs from "@otta/search/pages/Jobs/JobsRoutes";
import { Login } from "@otta/search/pages/Login";
import { SignupPage as Signup } from "@otta/search/pages/Signup";
import { Logout } from "@otta/search/pages/Logout";
import { LoggedInRoute } from "@otta/search/utils/routing/LoggedInRoute";
import { PreferencesSetRoute } from "@otta/search/utils/routing/PreferencesSetRoute";
import { SignedOutRoute } from "@otta/search/utils/routing/SignedOutRoute";
import { OAuthCallbacks } from "@otta/search/pages/OAuthCallbacks";
import { analyticsClickListener } from "@otta/search/utils/analytics/analyticsClickListener";
import { Toast } from "@otta/search/utils/toast";
import { Callback } from "@otta/search/pages/Callbacks";
import { useMaintenance } from "@otta/search/providers/maintenance";
import { Maintenance } from "@otta/search/components/Maintenance";
import { PreferencesRoute } from "@otta/search/pages/Preferences";
import { ApplyModalWrapper } from "@otta/search/pages/Dashboard/ApplyModalWrapper";
import {
  CurrentUserDocument,
  UpdateLastActiveDocument,
} from "@otta/search/schema";
import { JobsRedirect } from "@otta/search/pages/Jobs/JobsRedirect";
import { OAuthAuthorize } from "@otta/search/pages/OAuthAuthorize";
import Onboarding from "@otta/search/pages/Onboarding";
import { ApplySignUp } from "@otta/search/pages/ApplySignUp";
import { Ottacue } from "@otta/search/components/Ottacue";
import { useUserAnalytics } from "@otta/search/hooks/useUserAnalytics";
import { Redirect } from "@otta/search/router";
import { CompaniesIndex } from "@otta/search/pages/Companies/CompaniesIndex";
import { CompanyInfo } from "@otta/search/pages/Companies/CompanyInfo";
import { Company } from "@otta/search/pages/Companies/Company";
import { CompaniesHead } from "@otta/search/pages/Companies/CompaniesHead";

// This components assures the page is at least the height of the browser window
const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export function App(): React.ReactElement {
  const isInMaintenance = useMaintenance();
  const { data } = useQuery(CurrentUserDocument, { ssr: true });
  const location = useLocation();
  const [updateLastActive] = useMutation(UpdateLastActiveDocument);
  const noScroll = useRef(false);

  /**
   * Allows data-no-scroll set on links to prevent
   * this accursed auto-scroll-to-top behaviour
   */
  useEffect(() => {
    const preventScrolling = (e: Event) => {
      if (
        e.target &&
        e.target instanceof Element &&
        e.target.getAttribute("data-no-scroll")
      ) {
        noScroll.current = true;
      }
    };

    document.addEventListener("click", preventScrolling, { passive: true });
    return () => document.removeEventListener("click", preventScrolling);
  }, []);

  useEffect(() => {
    // Do not scroll to the top on company pages since they have their own scroll
    if (/^\/companies\//.test(location.pathname)) return;
    if (noScroll.current) {
      noScroll.current = false;
      return;
    }
    window?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  useEffect(() => {
    if (data?.currentUser?.id) {
      updateLastActive();
    }
  }, [data?.currentUser?.id, updateLastActive]);

  useEffect(() => {
    document.addEventListener("click", analyticsClickListener, {
      passive: true,
      capture: true,
    });
    return () => {
      document.removeEventListener("click", analyticsClickListener, {
        capture: true,
      });
    };
  }, []);

  useUserAnalytics();

  const axeptioStyles =
    "html.axeptio-widget--open .ax-website-overlay{top:unset !important;}.ax-widget-container{pointer-events: all}";

  return (
    <>
      <GlobalStyles />
      <style>{axeptioStyles}</style>
      <Helmet>
        <title>
          Welcome to the Jungle - The better way to find a job in tech
        </title>
        <meta name="author" content="Welcome to the Jungle" />
        <meta name="theme-color" content="#FFCD00" />
        <meta
          name="title"
          content="Welcome to the Jungle - The better way to find a job in tech"
        />
        <meta
          name="description"
          content="Only matches tailored to your preferences. Only the most exciting, innovative and fast-moving companies."
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Welcome to the Jungle" />
        <meta
          property="og:title"
          content="The better way to find a job in tech"
        />
        <meta
          property="og:description"
          content="Only matches tailored to your preferences. Only the most exciting, innovative and fast-moving companies."
        />
        <meta
          property="og:image"
          content="https://static.otta.com/open-graph-images/wttj.jpg"
        />
        <meta
          property="twitter:title"
          content="The better way to find a job in tech"
        />
        <meta
          property="twitter:description"
          content="Only matches tailored to your preferences. Only the most exciting, innovative and fast-moving companies."
        />
        <meta
          name="twitter:image"
          content="https://static.otta.com/open-graph-images/wttj.jpg"
        />
      </Helmet>
      {isInMaintenance ? (
        <Maintenance />
      ) : (
        <>
          <PageWrapper>
            <Routes>
              <Route path="oauth2/authorize" element={<OAuthAuthorize />} />
              <Route
                path="sign-up"
                element={
                  <SignedOutRoute>
                    <Signup />
                  </SignedOutRoute>
                }
              />
              <Route path="companies-sitemap" element={<SitemapIndex />} />
              <Route
                path="companies/write-an-endorsement"
                element={<EmployeeSurveyLandingPage />}
              />

              <Route
                path="companies/:companyUrlSafeName/write-an-endorsement"
                element={<EmployeeSurveySignUpPage />}
              />
              <Route path="/" element={<PageWithNavBar />}>
                <Route path="apply/sign-up" element={<ApplySignUp />}>
                  <Route index element={<ApplySignUp />} />
                  <Route path=":jobExternalId" element={<ApplySignUp />} />
                </Route>
                <Route
                  path="/"
                  element={<PreferencesSetRoute element={<Dashboard />} />}
                >
                  <Route
                    path="dashboard/jobs/:jobId/apply"
                    element={<ApplyModalWrapper />}
                  />
                </Route>
                <Route
                  path="dashboard/jobs/:jobId"
                  element={
                    <PreferencesSetRoute
                      element={
                        <>
                          <Dashboard />
                          <ShortlistJob />
                        </>
                      }
                      fallback={<JobsRedirect />}
                    />
                  }
                >
                  <Route index element={<JobContent />} />
                  <Route path="company" element={<CompanyProfile />} />
                </Route>

                <Route
                  path="messaging/*"
                  element={<PreferencesSetRoute element={<Messaging />} />}
                />

                <Route
                  path="login"
                  element={
                    <SignedOutRoute>
                      <Login />
                    </SignedOutRoute>
                  }
                />

                <Route
                  path="forgot-password"
                  element={
                    <SignedOutRoute>
                      <ForgotPassword />
                    </SignedOutRoute>
                  }
                />
                <Route
                  path="delete-my-account"
                  element={
                    <LoggedInRoute>
                      <DeleteMyAccount />
                    </LoggedInRoute>
                  }
                />
                <Route
                  path="logout"
                  element={
                    <LoggedInRoute>
                      <Logout />
                    </LoggedInRoute>
                  }
                />
                <Route path="jobs/*" element={<Jobs />} />
                <Route path="companies" element={<CompaniesHead />}>
                  <Route index element={<CompaniesIndex />} />
                  <Route path=":companyUrlSafeName" element={<Company />}>
                    <Route index element={<CompanyInfo />} />
                    <Route
                      path="reviews"
                      element={<Redirect to="..#endorsements-section" />}
                    />
                  </Route>
                </Route>
                <Route path="salaries/*" element={<Salaries />} />
                <Route
                  path="profile"
                  element={<PreferencesSetRoute element={<Profile />} />}
                />
                <Route
                  path="profile/import"
                  element={<PreferencesSetRoute element={<ProfileImport />} />}
                />
                <Route
                  path="profile/preferences"
                  element={<ProfilePreferences />}
                />
                <Route path="callback/*" element={<Callback />} />
                <Route
                  path="account-settings/*"
                  element={<AccountSettings />}
                />

                <Route
                  path="auth/oauth2/:method/callback"
                  element={<OAuthCallbacks />}
                />
                <Route
                  path="write-an-endorsement/:surveyExternalId/*"
                  element={<EmployeeSurvey />}
                />
                <Route
                  path="initial-preferences/*"
                  element={
                    <PreferencesRoute>
                      <InitialPreferences />
                    </PreferencesRoute>
                  }
                />
                <Route
                  path="preferences/*"
                  element={
                    <PreferencesRoute>
                      <JobPreferences />
                    </PreferencesRoute>
                  }
                />
                <Route
                  path="onboarding/*"
                  element={
                    <PreferencesRoute>
                      <Onboarding />
                    </PreferencesRoute>
                  }
                />
                <Route path="*" element={<Redirect to="/" />} />
              </Route>
            </Routes>
          </PageWrapper>
          <Ottacue />
          <Toast />
        </>
      )}
    </>
  );
}
